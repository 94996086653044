import { images } from '../../../../global/exports';
import Chart from '../../chart/chart';
import Chart2 from '../../chart/chart2';
import DashboardComponent from '../../dashboardComponent/dashboardComponent';

const Marketing = (props: any) => {
  const { group300, blue, green, red, dark } = images;
  const data = [
    { day: '0 ', sales: 500 },
    { day: '3 ', sales: 1800 },
    { day: '6 ', sales: 300 },
    { day: '9 ', sales: 7000 },
    { day: '12 ', sales: 3000 },
    { day: '15 ', sales: 4500 },
  ];
  const data2 = [
    { day: 0, Active: 1000, Total: 1500, av: 50 },
    { day: 1, Active: 600, Total: 1000, av: 70 },
    { day: 3, Active: 1250, Total: 1000, av: 30 },
    { day: 4, Active: 1100, Total: 2000, av: 50 },
    { day: 5, Active: 1100, Total: 800, av: 10 },
    { day: 7, Active: 250, Total: 50, av: 25 },
    { day: 9, Active: 1400, Total: 900, av: 20 },
  ];
  return (
    <>
      <div className="col-xl-9 col-lg-12 col-md-12  mt-3 mb-4 pr-0" style={{ height: '100%' }}>
        <div className="row">
          <div className="col-lg-6 pr-1">
            <div className="pl-2 pr-2 d-flex justify-content-start u-orderlefttabletotalcustomer">
              <Chart unit={' AED'} loading={props.loading} heading="Total Sales from Marketing" data={data} />
            </div>
          </div>
          <div className="col-lg-6 pr-0 pl-1 mt-md-3 mt-lg-0">
            <div className="pl-2 pr-2 d-flex justify-content-start u-orderlefttabletotalcustomer">
              <Chart loading={props.loading} heading="Total Marketing Messages" data={data} />
            </div>
          </div>
        </div>
        <div className="row">
          {
            <Chart2
              heading="Marketing Revenues"
              data={data2}
              x={'day'}
              y1={'Active'}
              y2={'Total'}
              unit={'  AED'}
              keymetrics={{
                name1: 'Av Messages/mark',
                name2: 'Av Sales/mark',
                name3: 'Av Mark/rest',
                name4: 'Av ROI/rest',
              }}
            />
          }
        </div>
      </div>
      <DashboardComponent fromHome={true} />
    </>
  );
};

export default Marketing;
