export const RestaurantAttributes = [
  {
    id: 2,
    title: 'NAME',
  },
  {
    id: 3,
    title: 'RESTAURANT EMAIL',
  },
  {
    id: 4,
    title: 'RESTAURANT CONTACT',
  },
  {
    id: 5,
    title: 'ADDRESS',
  },
  {
    id: 9,
    title: 'STATUS',
  },
  {
    id: 10,
    title: 'MONTH SALES',
  },
  {
    id: 11,
    title: 'MONTH ORDERS',
  },
  {
    id: 11,
    title: 'Deep Link',
  },
];
export const CustomerTable = [
  {
    id: 1,
    title: 'ID',
  },
  {
    id: 2,

    title: 'NAME',
  },

  {
    id: 4,
    title: 'EMAIL',
  },
  {
    id: 5,
    title: 'PHONE',
  },
  {
    id: 7,
    title: 'OTP VERIFIED',
  },
  {
    id: 8,
    title: 'JOINED ON',
  },
  {
    id: 9,
    title: 'MONTH SPENT',
  },
  {
    id: 10,
    title: 'MONTH ORDERS',
  },
  {
    id: 11,
    title: 'ACTION',
  },
];
export const CustomerOrderHistoryTable = [
  {
    id: 1,
    title: 'Order ID',
  },
  {
    id: 2,
    title: 'Restaurant',
  },
  {
    id: 3,
    title: 'Area',
  },
  {
    id: 4,
    title: 'Cusine',
  },
  {
    id: 5,
    title: 'Date',
  },
  {
    id: 6,
    title: 'Time',
  },
  {
    id: 7,
    title: 'Table',
  },
  {
    id: 8,
    title: 'Customers Order',
  },
  {
    id: 9,
    title: 'Paid On Restro',
  },
];
export const CampaignInProgressTable = [
  { id: 0, title: '' },
  {
    id: 1,
    title: 'RESTAURANT',
  },
  {
    id: 2,

    title: 'CAMPAIGN NAME',
  },

  {
    id: 4,
    title: 'DISCOUNT',
  },
  {
    id: 5,
    title: 'CODE',
  },
  {
    id: 7,
    title: 'VALIDITY',
  },
  {
    id: 8,
    title: 'BUDGET',
  },
  {
    id: 9,
    title: '# ASSIGNED CUSTOMERS',
  },
  {
    id: 10,
    title: 'NEXT SENDING DATE',
  },
  {
    id: 11,
    title: '# MESSAGES SENT',
  },
  {
    id: 12,
    title: 'CONVERSION RATE',
  },
  {
    id: 13,
    title: 'SALES',
  },
];
export const CampaignToBeReviewedTable = [
  {
    id: 1,
    title: 'RESTAURANT',
  },
  {
    id: 2,

    title: 'AREA',
  },

  {
    id: 4,
    title: 'CUISINE',
  },
  {
    id: 5,
    title: 'CAMPAIGN NAME',
  },
  {
    id: 7,
    title: 'MESSAGE',
  },
  {
    id: 8,
    title: 'DISCOUNT',
  },
  {
    id: 9,
    title: 'CODE',
  },
  {
    id: 10,
    title: 'BUDGET',
  },
  {
    id: 11,
    title: '# ASSIGNED CUSTOMERS',
  },
  {
    id: 12,
    title: 'NEXT SENDING DATE',
  },
  {
    id: 13,
    title: 'ACTION',
  },
];
export const AssignCustomersModalTable = [
  {
    id: 0,
    title: '',
  },
  {
    id: 1,
    title: 'CUSTOMER Name',
  },
  {
    id: 2,
    title: 'FREQUENCY AT RESTAURANT',
  },
  {
    id: 3,
    title: 'DISCOUNT HISTORY FROM RESTAURANT',
  },
  {
    id: 4,
    title: 'TOTAL ACTIVE DISCOUNTS',
  },
  {
    id: 5,
    title: '3M DISC. CONVERSION',
  },
  {
    id: 6,
    title: 'ORDERS THIS MONTH ',
  },
  {
    id: 7,
    title: 'SPENT THIS MONTH ',
  },
];
export const AssignCustomersModalTable2 = [
  {
    id: 0,
    title: '',
  },
  {
    id: 1,
    title: 'CUSTOMER ID',
  },
  {
    id: 2,
    title: '# RESTAURANTS VISITED',
  },
  {
    id: 3,
    title: 'ORDERS THIS MONTH',
  },
  {
    id: 4,
    title: 'SPENT THIS MONTH',
  },
  {
    id: 5,
    title: 'WEEKS SINCE LAST ORDER',
  },
];
export const CustomerOrdersModalTable = [
  {
    id: 0,
    title: '',
  },
  {
    id: 1,
    title: 'ORDER ID',
  },
  {
    id: 2,
    title: 'DATE',
  },
  {
    id: 3,
    title: 'RESTAURANT',
  },
  {
    id: 4,
    title: 'CUISINE',
  },
  {
    id: 5,
    title: 'AREA',
  },
  {
    id: 6,
    title: 'SPENT',
  },
];
export const CampaignDetailsModalTable = [
  {
    id: 1,
    title: 'ORDER ID',
  },
  {
    id: 2,
    title: 'CUSTOMER ID',
  },
  {
    id: 3,
    title: 'DATE',
  },
  {
    id: 4,
    title: 'SPENT',
  },
  {
    id: 5,
    title: 'COMMISSION',
  },
  {
    id: 6,
    title: 'REVENUE FOR RESTRO',
  },
];
export const TranscationDetailsModalTable = [
  {
    id: 1,
    title: 'DATE',
  },
  {
    id: 2,
    title: 'TRANSCATION TYPE',
  },
  {
    id: 3,
    title: 'ORDER ID',
  },
  {
    id: 4,
    title: 'TOTAL BILL',
  },
  {
    id: 5,
    title: 'PAID ON RESTRO',
  },
  {
    id: 6,
    title: 'TIPS ON RESTRO',
  },
  {
    id: 7,
    title: 'TRANSCATION FEES',
  },
  {
    id: 8,
    title: 'NUMBER OF MESSAGES',
  },
  {
    id: 9,
    title: 'MARKETING COMMISSIONS',
  },
  {
    id: 10,
    title: 'COMPAIGN NAME',
  },
  {
    id: 11,
    title: 'RESTRO DISCOUNTS',
  },
  {
    id: 12,
    title: 'TOTAL',
  },
];
export const AccountingOrdersRestro = [
  {
    id: 1,
    title: 'TRANSACTION ID',
  },
  {
    id: 2,
    title: 'ORDER ID',
  },
  {
    id: 3,
    title: 'USER ID',
  },
  {
    id: 4,
    title: 'RESTAURANT',
  },
  {
    id: 5,
    title: 'DATE',
  },
  {
    id: 6,
    title: 'BILL AMOUNT',
  },
  {
    id: 7,
    title: 'AMOUNT PAID ON RESTRO',
  },
  {
    id: 8,
    title: 'RESTRO DISCOUNTS',
  },
  {
    id: 9,
    title: 'TIPS (ON RESTRO)',
  },
];
export const AccountingMarketingFees = [
  {
    id: 1,
    title: 'RESTAURANT',
  },
  {
    id: 2,
    title: 'MARKETING TYPE',
  },
  {
    id: 3,
    title: 'CAMPAIGN NAME',
  },
  {
    id: 4,
    title: 'CODE',
  },
  {
    id: 5,
    title: 'DATE LAUNCHED',
  },
  {
    id: 6,
    title: '# MESSAGES SENT',
  },
  {
    id: 7,
    title: 'COST/MESSAGE',
  },
  {
    id: 8,
    title: 'TOTAL SALES',
  },
  {
    id: 9,
    title: 'COMMISSION',
  },
  {
    id: 10,
    title: 'REVENUES FOR RESTRO',
  },
];
export const AccountingSettlements = [
  {
    id: 1,
    title: 'PERIOD',
  },
  {
    id: 2,
    title: 'RESTAURANT',
  },
  {
    id: 3,
    title: 'TOTAL SALES',
  },
  {
    id: 4,
    title: 'PAID ON RESTRO',
  },
  {
    id: 5,
    title: 'TRANSACTION FEES',
  },
  {
    id: 6,
    title: 'MARKETING EXPENSES',
  },
  {
    id: 7,
    title: 'RESTRO DISCOUNTS',
  },
  {
    id: 8,
    title: 'PAYMENT AMOUNT',
  },
  {
    id: 9,
    title: 'STATUS',
  },
  {
    id: 10,
    title: 'TRANSACTION PROOF',
  },
];
export const IssueTable = [
  {
    id: 1,
    title: 'ISSUE ID',
  },
  {
    id: 2,

    title: 'DATE',
  },

  {
    id: 4,
    title: 'ISSUER TYPE',
  },
  {
    id: 5,
    title: 'ISSUER ID',
  },
  {
    id: 7,
    title: 'ISSUER NAME',
  },
  {
    id: 8,
    title: 'ORDER ID',
  },
  {
    id: 9,
    title: 'ISSUE TITLE',
  },
  {
    id: 10,
    title: 'STATUS',
  },
  {
    id: 11,
    title: 'ACTION',
  },
];
export const UsersTable = [
  {
    id: 2,

    title: 'FIRST NAME',
  },

  {
    id: 4,
    title: 'LAST NAME',
  },
  {
    id: 5,
    title: 'EMAIL ADDRESS',
  },
  {
    id: 7,
    title: 'PHONE',
  },
  {
    id: 8,
    title: 'ROLE',
  },
  {
    id: 9,
    title: 'STATUS',
  },
  {
    id: 10,
    title: 'ACTION',
  },
];
export const permissionData = [
  {
    id: 'dashboards',
    name: 'DASHBOARD FUNCTIONS',
    options: [{ id: '11', name: 'View', symbol: 'view' }],
  },
  {
    id: 'restaurants',
    name: 'RESTAURANT FUNCTIONS',
    options: [
      { id: '21', name: 'View', symbol: 'view' },
      { id: '22', name: 'Add Restaurant', symbol: 'add_restaurant' },
      { id: '23', name: 'Edit Restaurant', symbol: 'edit_restaurant' },
      { id: '24', name: 'Edit Orders', symbol: 'edit_order' },
      { id: '25', name: 'Edit Menu', symbol: 'edit_menu' },
      { id: '26', name: 'Edit Marketing', symbol: 'edit_marketing' },
      { id: '27', name: 'Edit Users', symbol: 'edit_user' },
    ],
  },
  {
    id: 'customers',
    name: 'CUSTOMERS FUNCTIONS',
    options: [
      { id: '31', name: 'View', symbol: 'view' },
      { id: '32', name: 'Add Customer', symbol: 'add_customer' },
      { id: '33', name: 'Edit Customer', symbol: 'edit_customer' },
    ],
  },
  {
    id: 'marketings',
    name: 'MARKETING FUNCTIONS',
    options: [
      { id: '41', name: 'View', symbol: 'view' },
      { id: '42', name: 'Assign Customer', symbol: 'assign_customer' },
      {
        id: '43',
        name: 'Approve/reject Campaign',
        symbol: 'approve_reject_campaign',
      },
    ],
  },
  {
    id: 'accountings',
    name: 'ACCOUNTING FUNCTIONS',
    options: [
      { id: '51', name: 'View', symbol: 'view' },
      { id: '52', name: 'Edit', symbol: 'edit' },
    ],
  },
  {
    id: 'issues',
    name: 'ISSUES FUNCTIONS',
    options: [
      { id: '61', name: 'View', symbol: 'view' },
      { id: '62', name: 'Reply Issues', symbol: 'reply_issue' },
    ],
  },
  {
    id: 'users',
    name: 'USERS FUNCTIONS',
    options: [
      { id: '71', name: 'View', symbol: 'view' },
      { id: '72', name: 'Add Users', symbol: 'add_user' },
      { id: '73', name: 'Edit Users', symbol: 'edit_user' },
    ],
  },
] as any;
export const RestroDiscountCampaign = [
  {
    id: 0,
    title: '',
  },
  {
    id: 1,
    title: 'CAMPAIGN NAME',
  },
  {
    id: 2,

    title: 'DISTRIBUTION',
  },
  {
    id: 4,
    title: 'DISCOUNT',
  },
  {
    id: 5,
    title: 'UP TO',
  },
  {
    id: 6,
    title: 'CODE',
  },
  {
    id: 7,
    title: 'VALIDITY',
  },
  {
    id: 8,
    title: '# ASSIGNED CUSTOMERS',
  },
  {
    id: 9,
    title: 'NEXT SENDING DATE',
  },

  {
    id: 10,
    title: '# MESSAGES SENT',
  },
  {
    id: 11,
    title: 'CONVERSION RATE',
  },
  {
    id: 12,
    title: 'SALES',
  },
  {
    id: 13,
    title: 'SPENT IN DISCOUNTS',
  },
  {
    id: 14,
    title: '',
  },
];
export const RestroSignInDiscount = [
  {
    id: 0,
    title: '',
  },
  {
    id: 1,
    title: 'CAMPAIGN NAME',
  },
  {
    id: 2,

    title: 'DISTRIBUTION',
  },
  {
    id: 4,
    title: 'DISCOUNT',
  },
  {
    id: 5,
    title: 'UP TO',
  },
  {
    id: 6,
    title: 'CODE',
  },
  {
    id: 7,
    title: 'VALIDITY',
  },
  {
    id: 8,
    title: 'NEXT SENDING DATE',
  },

  {
    id: 9,
    title: 'NUMBER OF CODES USED',
  },

  {
    id: 10,
    title: 'SALES',
  },
  {
    id: 11,
    title: 'SPENT IN DISCOUNTS',
  },
  {
    id: 12,
    title: '',
  },
];
