import React from 'react';
import Chart from '../../chart/chart';
import Chart2 from '../../chart/chart2';
import DashboardComponent from '../../dashboardComponent/dashboardComponent';

const Sales = (props: any) => {
  const { fromDate, toDate } = props;
  const data2 = [
    { day: 0, Active: 1000, Total: 1500, av: 50 },
    { day: 1, Active: 600, Total: 1000, av: 70 },
    { day: 3, Active: 1250, Total: 1000, av: 30 },
    { day: 4, Active: 1100, Total: 2000, av: 50 },
    { day: 5, Active: 1100, Total: 800, av: 10 },
    { day: 7, Active: 250, Total: 500, av: 25 },
    { day: 9, Active: 1400, Total: 900, av: 20 },
  ];

  const basketSize = [] as any;
  const totalSales = [] as any;
  const orders = [] as any;
  const [keyMetrics, setKeyMetrics] = React.useState({
    sales: 0,
    orders: 0,
    basket: 0,
    ordersDiscount: 0,
  });
  const [keyMetrics2, setKeyMetrics2] = React.useState({
    sales: 0,
    orders: 0,
    basket: 0,
    ordersDiscount: 0,
  });
  const rawdataCustomers = [] as any;
  const rawdataRestaurants = [] as any;
  const [customerGraph, setCustomerGraph] = React.useState([] as any);
  const [restaurantGraph, setRestaurantGraph] = React.useState([] as any);
  const totalCustomersActive = customerGraph.length
    ? customerGraph.reduce((total: any, c: any) => c.Total + total, 0)
    : 1;
  const totalRestaurantsActive = restaurantGraph.length
    ? restaurantGraph.reduce((total: any, r: any) => r.Total + total, 0)
    : 1;
  const setMetrics = () => {
    const sales = totalSales.map((item: any) => item?.total_sales).reduce((a: any, b: any) => a + b, 0);
    const baskets = basketSize.map((item: any) => item?.total_basket_size).reduce((a: any, b: any) => a + b, 0);
    const order = orders.map((item: any) => item?.total_orders).reduce((a: any, b: any) => a + b, 0);
    setKeyMetrics({
      ...keyMetrics,
      sales: totalCustomersActive === 1 ? 0 : sales / totalCustomersActive,
      basket: totalCustomersActive === 1 ? 0 : baskets / totalCustomersActive,
      orders: totalCustomersActive === 1 ? 0 : order / totalCustomersActive,
    });
    setKeyMetrics2({
      ...keyMetrics2,
      sales: totalRestaurantsActive === 1 ? 0 : sales / totalRestaurantsActive,
      basket: totalRestaurantsActive === 1 ? 0 : totalCustomersActive / totalRestaurantsActive,
      orders: totalRestaurantsActive === 1 ? 0 : order / totalRestaurantsActive,
    });
  };
  const date = (d: any) => `${d.month.toString().length <= '1' ? `0${d.month}` : d.month}/${d.day}/${d.year}`;

  let saleGraphData1 = [] as any;
  const ordersGraphData1 = [] as any;
  const saleGraphData = totalSales.map((d: any) => ({
    day: new Date(date(d)).toLocaleDateString(),
    sales: Number(d?.total_sales?.toFixed(2)),
  }));
  const ordersGraphData = orders.map((d: any) => ({
    day: new Date(date(d)).toLocaleDateString(),
    sales: Number(d?.total_orders?.toFixed(2)),
  }));
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  function dateDiffInDays(a: any, b: any) {
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }
  saleGraphData1 = [];
  for (let i = 0; i <= dateDiffInDays(fromDate, toDate); i++) {
    saleGraphData1.push({
      day: new Date(new Date(fromDate).setDate(new Date(fromDate).getDate() + i)).toLocaleDateString(),
      sales: 0,
    });
  }

  for (let i = 0; i <= dateDiffInDays(fromDate, toDate); i++) {
    ordersGraphData1.push({
      day: new Date(new Date(fromDate).setDate(new Date(fromDate).getDate() + i)).toLocaleDateString(),
      sales: 0,
    });
  }
  for (let j = 0; j < ordersGraphData.length; j++) {
    const element = ordersGraphData1.filter((d: any) => d.day === ordersGraphData[j]?.day)?.[0];
    const index = ordersGraphData1.indexOf(element);
    ordersGraphData1[index] = ordersGraphData[j];
  }
  for (let j = 0; j < saleGraphData.length; j++) {
    const element = saleGraphData1.filter((d: any) => d.day === saleGraphData[j]?.day)?.[0];
    const index = saleGraphData1.indexOf(element);
    saleGraphData1[index] = saleGraphData[j];
  }

  return (
    <>
      {' '}
      <div className="col-xl-9 col-lg-12 col-md-12  mt-3 mb-4 pr-0" style={{ height: '100%' }}>
        <div className="row">
          <div className="col-lg-6 pr-3 pr-lg-1">
            <div className="pl-xl-2 pr-xl-2 d-flex justify-content-start u-orderlefttabletotalcustomer">
              <Chart unit={'  AED'} loading={props.loading} heading="Total Sales" data={saleGraphData1} />
            </div>
          </div>
          <div className="col-lg-6 pr-3 pr-lg-0 pl-2 pl-lg-1 mt-lg-0 mt-3">
            <div className="pl-2 pr-2 d-flex justify-content-start u-orderlefttabletotalcustomer">
              <Chart loading={props.loading} heading="Total Orders" data={ordersGraphData1} />
            </div>
          </div>
        </div>
        <div className="d-flex flex-row flex-wrap">
          {
            <Chart2
              heading="Customers"
              data={customerGraph}
              x={'Day'}
              y1={'Active'}
              y2={'Total'}
              keymetrics={{
                name1: 'Av Sales/Customer',
                name2: 'Av orders/customer',
                name3: 'Av basket size',
                name4: 'Av Time at Restaurant',
              }}
              keyMetrics={keyMetrics}
            />
          }
          {
            <Chart2
              heading="Restaurants"
              data={restaurantGraph}
              x={'Day'}
              y1={'Active'}
              y2={'Total'}
              keymetrics={{
                name1: 'Av Sales/rest',
                name2: 'Av orders/rest',
                name3: 'Av Customer/rest',
                name4: 'Av Repeat Cust/rest',
              }}
              keyMetrics={keyMetrics2}
            />
          }
        </div>
      </div>
      <DashboardComponent fromHome={true} />
    </>
  );
};

export default Sales;
