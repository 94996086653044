import { auth } from 'config/firebase';
import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { BounceLoader } from 'react-spinners';
import RestaurantScreen from 'screens/restaurant';
import UserScreen from 'screens/user';
import NotFound from './components/global/notFound/NotFound';
import AuthRoute from './components/global/privateRoute/authRoute';
import PrivateRoute from './components/global/privateRoute/privateRoute';
import Dashboard from './components/local/dashboard';
import Login from './components/local/login';
import BrowserRoutes from './routes/BrowserRoutes';
import { useRestaurantStore } from './store';

function App() {
  const [pageLoading, setPageLoading] = useState(true);
  const { setLoggedin } = useRestaurantStore((state) => state);

  useEffect(() => {
    const unsub = auth.onAuthStateChanged((user) => {
      if (user) {
        setLoggedin(true);
      } else {
        setLoggedin(false);
      }
      setPageLoading(false);
    });
    return unsub;
  }, []);

  return !pageLoading ? (
    <Routes>
      <Route path={BrowserRoutes.LOGIN} element={<AuthRoute component={Login} />}></Route>
      <Route path={BrowserRoutes.RESTAURANTS} element={<PrivateRoute component={RestaurantScreen} />}></Route>
      <Route path={BrowserRoutes.DASHBOARD} element={<PrivateRoute component={Dashboard} />}></Route>
      {/* <Route path={`${BrowserRoutes.MARKETING}/campaigns`} element={<PrivateRoute component={Campaign} />}></Route> */}
      {/* <Route path={`${BrowserRoutes.MARKETING}/restro`} element={<PrivateRoute component={Restro} />}></Route> */}
      {/* <Route path={BrowserRoutes.ACCOUNTING} element={<PrivateRoute component={Accounting} />}></Route> */}
      {/* <Route path={BrowserRoutes.ISSUES} element={<PrivateRoute component={Issues} />}></Route> */}
      <Route path={BrowserRoutes.USERS} element={<PrivateRoute component={UserScreen} />}></Route>
      {/* <Route path={BrowserRoutes.CUSTOMERS} element={<PrivateRoute component={Customers} />}></Route> */}
      <Route path={BrowserRoutes.NOT_FOUND} element={<NotFound />}></Route>
    </Routes>
  ) : (
    <div className="loader">
      <BounceLoader color="#834bff" />
    </div>
  );
}

export default App;
