import { useState } from 'react';
import { utils } from '../../../../../global/exports';
import RatingStars from './ratingStars';

const ReviewDetails = (props: any) => {
  const { setData, dataType } = props;
  const [filter, setFilter] = useState('All');

  const [data, setdata] = useState(props.data);

  const handleFilter = (e: any) => {
    const Data = [...props.data];
    if (e.target.value !== 'All') {
      const filteredData = Data.filter(
        (d: any) => d.rating >= Number(e.target.value) && d.rating < Number(e.target.value) + 1,
      );
      setdata(filteredData);
      setFilter(e.target.value);
    } else {
      setData(Data);
    }
  };
  const sort = (e: any) => {
    if (e.target.value === 'mr') {
    } else if (e.target.value === 'tr') {
    }
  };

  return (
    <div
      style={{ maxHeight: '70vh', minHeight: '70vh' }}
      className="col-xl-4 col-lg-12 col-lg-10 col-md-12 mt-3  mb-4 u-orderlefttabletotalcustomer"
    >
      <h2 className="u-orderheading">{dataType}</h2>
      <div className="row">
        <div className="col-lg-4">
          <h2 className="u-usermanagement001">Sort by</h2>
          <select
            onChange={sort}
            style={{ width: '100%' }}
            className="form-select u-menuselect002"
            aria-label="Default select example"
          >
            <option selected className="u-menuselect001"></option>
            <option value="mr">Most Recent</option>
            <option value="tr">Top Reviews</option>
          </select>
        </div>
        <div className="col-lg-4">
          <h2 className="u-usermanagement001">Filter by</h2>
          <select
            value={filter}
            onChange={(e: any) => handleFilter(e)}
            style={{ width: '100%' }}
            className="form-select u-menuselect002"
            aria-label="Default select example"
          >
            <option selected className="u-menuselect001">
              All
            </option>
            <option value="1">1 Star</option>
            <option value="2">2 Star</option>
            <option value="3">3 Star</option>
            <option value="4">4 Star</option>
            <option value="5">5 Star</option>
          </select>
        </div>
      </div>

      {data.map((d: any) => (
        <div key={d.id}>
          <h2 className="u-paragrphname5576">
            {d.user_name} {<RatingStars review={data[0]} />}
            <span>{d.rating.toFixed(1)}</span>{' '}
            <span className="u-numberrowwwww ml-3">{utils.localDate(d.time?._seconds)}</span>
          </h2>
          <p className="u-numberrowwwww">{d.comment}</p>
          <hr className="solid" />
        </div>
      ))}

      <button
        style={{ position: 'absolute', bottom: '20px' }}
        onClick={() => setData([])}
        className="u-menubutton001 mt-4"
      >
        Close
      </button>
    </div>
  );
};

export default ReviewDetails;
