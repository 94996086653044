import Ratings from './ratings';

const RestaurantRatings = ({ ratings, setData, setDataType }: any) => (
  <div className="row u-orderlefttablesatisfaction">
    <div className="col-lg-5">
      <h2 className="u-orderheading ">Restaurant rating</h2>
      <div className="d-flex ">
        <h2 className="u-saleflash001">
          <span>{ratings?.average_rating?.rating?.toFixed(1)} </span>
          <i style={{ color: '#834BFF' }} className="ml-1  fa-solid fa-star"></i>
        </h2>
        <h2 className="u-saleflash001 ml-2">Based on {ratings?.average_rating?.total_reviews ?? 0} ratings</h2>
      </div>
      <div className="d-flex justify-content-between" style={{ width: '200px' }}>
        <h2 className="u-saleflash001 mt-2">Food</h2>
        <h2 className="u-saleflash001  mt-2 ml-5">
          <span className="ml-5">{ratings?.average_rating?.food?.toFixed(1) ?? 0}</span>
          <i style={{ color: '#834BFF' }} className="ml-1  fa-solid fa-star"></i>{' '}
        </h2>
      </div>
      <div className="d-flex justify-content-between" style={{ width: '200px' }}>
        <h2 className="u-saleflash001 mt-2">Service</h2>
        <h2 className="u-saleflash001 text-right mt-2 ml-5">
          <span className="ml-5">{ratings?.average_rating?.service?.toFixed(1) ?? 0}</span>
          <i style={{ color: '#834BFF' }} className="ml-1  fa-solid fa-star"></i>{' '}
        </h2>
      </div>
      <div className="d-flex justify-content-between" style={{ width: '200px' }}>
        <h2 className="u-saleflash001 mt-2">Ambiance</h2>
        <h2 className="u-saleflash001  mt-2 ml-4">
          <span className="ml-5">{ratings?.average_rating?.ambiance?.toFixed(1) ?? 0}</span>
          <i style={{ color: '#834BFF' }} className="ml-1  fa-solid fa-star"></i>{' '}
        </h2>
      </div>
    </div>
    <div className="col-lg-7 mt-4 p-1">
      <Ratings star={5} number={'five'} ratings={ratings} />
      <Ratings star={4} number={'four'} ratings={ratings} />
      <Ratings star={3} number={'three'} ratings={ratings} />
      <Ratings star={2} number={'two'} ratings={ratings} />
      <Ratings star={1} number={'one'} ratings={ratings} />

      <div>
        <div className="side1 mt-1">
          <span
            onClick={() => {
              setData(ratings.reviews);
              setDataType('All Reviews');
            }}
            style={{ cursor: 'pointer' }}
            className="u-numberrowwwww-ratin001"
          >
            Read all reviews
            <i className="fa-solid fa-chevron-right ml-2"></i>
          </span>
        </div>
      </div>
    </div>
  </div>
);

export default RestaurantRatings;
