import { auth } from 'config/firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { toast } from 'react-toastify';
import ServerRoutes from '../routes/ServerRoutes';
import { apiRequest } from '../utils/axios';

export const login = async (body: any) => {
  signInWithEmailAndPassword(auth, body?.email, body?.password)
    .then((res) => {
      toast.success('Login successfully');
    })
    .catch((error: any) => {
      toast.error(error.message);
    });
};
export const getCurrentUser = () => auth.currentUser;
export const logout = () => {
  auth
    .signOut()
    .then(() => {
      toast.success('Logged out successfully');
    })
    .catch((error) => {
      toast.error(error.message);
    });
};
export const createUser = async (body: any) => apiRequest(ServerRoutes.CREATE_USER, 'post', body);
// .then((res: any) => {
//   toast.success('User created');
//   mutate(ServerRoutes.GET_USERS);
//   setState(false);
// })
// .catch((error: any) => {
//   console.log(error);
//   toast.error(error?.message);
//   setState(false);
// });
export const updateUser = async (userId: string, body: any) =>
  apiRequest(ServerRoutes.UPDATE_USER(userId), 'patch', body);
// .then((res: any) => {
//   mutate(ServerRoutes.GET_USERS);
//   toast.success('User updated');
//   setState(false);
// })
// .catch((error: any) => {
//   toast.error(error.message);
//   setState(false);
// });
