const BrowserRoutes = {
  LOGIN: '/',
  RESTAURANTS: '/restaurants',
  DASHBOARD: '/dashboard',
  CUSTOMERS: '/customers',
  MARKETING: '/marketing',
  ISSUES: '/issues',
  USERS: '/users',
  ACCOUNTING: '/accounting',
  NOT_FOUND: '*',
};
export default BrowserRoutes;
