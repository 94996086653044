import Header from '../layout/header';
import SideBar from '../layout/sideBar';
// this component will render any child component with sidebar and header
const withMainLayout = (WrappedComponent: any) => {
  const WithMain = (props: any) => (
    <div className="backgroundColor p-0" style={{ overflowX: 'hidden' }}>
      <div className="row">
        <div
          className="col-xl-1 col-lg-2 col-md-2 pr-0 sb-sidenav-dark"
          style={{
            height: '100%',
            position: 'fixed',
            zIndex: 2,
          }}
        >
          <SideBar />
        </div>
        <div className="col-md-2 col-lg-2 col-xl-1"></div>
        <div className="col-lg-10 col-xl-11 col-md-10 p-0">
          <Header />
          <div>
            <WrappedComponent {...props} />
          </div>
        </div>
      </div>
    </div>
  );

  return WithMain;
};

export default withMainLayout;
