import React from 'react';
import ReactDOM from 'react-dom';
import 'react-phone-number-input/style.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { SWRConfig } from 'swr';
import App from './App';
import './assets/css/new-style.css';
import './assets/css/styles.css';
import { apiRequest } from './utils/axios';

const fetcher = (link: any) => apiRequest(link, 'get', null).then((res: any) => res.data);

ReactDOM.render(
  <React.StrictMode>
    <SWRConfig value={{ fetcher }}>
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Router>
        <App />
      </Router>
    </SWRConfig>
  </React.StrictMode>,
  document.getElementById('root'),
);
