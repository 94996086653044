import Dialog from '@mui/material/Dialog';
import { FC, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-number-input';
import { BounceLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import ServerRoutes from 'routes/ServerRoutes';
import { createRestaurant, updateRestaurant } from 'services/restaurant';
import { Restaurant } from 'src/@types/restaurant';
import { mutate } from 'swr';
import { z } from 'zod';

interface Props {
  show: boolean;
  setShow: (show: boolean) => void;
  restaurant: Partial<Restaurant>;
}

const requireSign = <span style={{ color: 'red' }}>*</span>;
const errorInputStyle = { borderStyle: 'solid', borderColor: 'red' };

const RestaurantModal: FC<Props> = ({ show, setShow, restaurant }) => {
  const isNewRestaurant = Object.keys(restaurant || {}).length === 0;
  const [loading, setLoading] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm<Restaurant>(
    !isNewRestaurant
      ? {
          defaultValues: {
            ...restaurant,
          },
        }
      : {
          defaultValues: {
            is_active: true,
          },
        },
  );

  const createOnSubmit: SubmitHandler<Restaurant> = async (data) => {
    setLoading(true);
    try {
      const response = await createRestaurant(data);
      toast.success('Restaurant Created Successfully');
      mutate(ServerRoutes.GET_RESTAURANTS());
      setLoading(false);
      setShow(false);
    } catch (error: any) {
      toast.error(error?.message);
      console.log(error);
      setLoading(false);
    }
  };

  const updateOnSubmit: SubmitHandler<Restaurant> = async (data) => {
    setLoading(true);
    try {
      const response = await updateRestaurant(restaurant.id || '', data);
      toast.success('Restaurant Updated Successfully');
      mutate(ServerRoutes.GET_RESTAURANTS());
    } catch (error: any) {
      toast.error(error?.message);
      console.log(error);
    }
    setLoading(false);
    setShow(false);
  };

  return (
    <>
      <Dialog open={show} fullWidth={true} maxWidth="lg">
        <div className="loader">
          <BounceLoader loading={loading} color="#834bff" />
        </div>
        <div className="modalBody">
          <div className="modalHeader">
            <h5 className="modalHeading mt-2" id="exampleModalLabel">
              Restaurant information
            </h5>
            <svg
              onClick={(): void => setShow(false)}
              className="modalCloseButton"
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.9664 10.3596L10.3598 20.9662C9.99744 21.3286 9.3964 21.3286 9.03401 20.9662C8.67161 20.6038 8.67161 20.0028 9.03401 19.6404L19.6406 9.03377C20.003 8.67138 20.604 8.67138 20.9664 9.03377C21.3288 9.39616 21.3288 9.9972 20.9664 10.3596Z"
                fill="#292D32"
              />
              <path
                d="M20.9664 20.9662C20.604 21.3286 20.003 21.3286 19.6406 20.9662L9.03401 10.3596C8.67161 9.99724 8.67161 9.3962 9.03401 9.0338C9.3964 8.67141 9.99744 8.67141 10.3598 9.0338L20.9664 19.6404C21.3288 20.0028 21.3288 20.6038 20.9664 20.9662Z"
                fill="#292D32"
              />
            </svg>
          </div>
          <div>
            <div className="mt-2">
              <div className="modalBodyHeading">General information</div>
              <div className="d-flex flex-row mt-3">
                <div className="d-flex flex-row align-items-center">
                  <label className="inputLabel">Restaurant Name {requireSign}</label>
                  <input
                    className="inputField"
                    style={errors.restaurant_name && errorInputStyle}
                    {...register('restaurant_name', { required: true })}
                  />
                </div>
                <div className="d-flex flex-row align-items-center ml-5">
                  <label className="inputLabel">Key Contact Name {requireSign}</label>
                  <input
                    {...(!isNewRestaurant ? { disabled: true } : { disabled: false })}
                    className="inputField"
                    style={errors.contact_name && errorInputStyle}
                    {...register('contact_name', { required: true })}
                  />
                </div>
              </div>
            </div>
            <div className="mt-3">
              <div className="d-flex flex-row">
                <div className="d-flex flex-row align-items-center">
                  <label className="inputLabel">Restaurant Email {requireSign}</label>
                  <input
                    type="email"
                    className="inputField"
                    style={errors.restaurant_email && errorInputStyle}
                    {...register('restaurant_email', {
                      validate: (v) => z.string().email().safeParse(v).success,
                      required: true,
                    })}
                  />
                </div>
                <div className="d-flex flex-row align-items-center ml-5">
                  <label className="inputLabel">Contact Email {requireSign}</label>
                  <input
                    {...(!isNewRestaurant ? { disabled: true } : { disabled: false })}
                    type="email"
                    className="inputField"
                    style={errors.contact_email && errorInputStyle}
                    {...register('contact_email', {
                      validate: (v) => z.string().email().safeParse(v).success,
                      required: true,
                    })}
                  />
                </div>
              </div>
            </div>
            <div className="mt-3">
              <div className="d-flex flex-row">
                <div className="d-flex flex-row align-items-center">
                  <label className="inputLabel">Restaurant Phone {requireSign}</label>
                  <Controller
                    name="restaurant_phone"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <PhoneInput
                        className="inputField"
                        style={errors.restaurant_phone && errorInputStyle}
                        value={value}
                        onChange={onChange}
                        defaultCountry="AE"
                        id="restaurant_phone"
                      />
                    )}
                  />
                </div>
                <div className="d-flex flex-row align-items-center ml-5">
                  <label className="inputLabel">Contact Phone {requireSign}</label>
                  <Controller
                    name="contact_phone"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <PhoneInput
                        {...(!isNewRestaurant ? { disabled: true } : { disabled: false })}
                        className="inputField"
                        style={errors.contact_phone && errorInputStyle}
                        value={value}
                        onChange={onChange}
                        defaultCountry="AE"
                        id="contact_phone"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="mt-3">
              <div className="d-flex flex-row">
                <div className="d-flex flex-row align-items-center">
                  <label className="inputLabel">Founding Date {requireSign}</label>
                  <input
                    type="date"
                    className="inputField"
                    style={errors.founding_date && errorInputStyle}
                    {...register('founding_date', {
                      setValueAs: (v) => new Date(v),
                      validate: (v) => z.date().safeParse(v).success,
                      required: true,
                    })}
                  />
                </div>
              </div>
            </div>
            <div className="mt-3">
              <div className="d-flex flex-row">
                <div className="d-flex flex-row align-items-center">
                  <label className="inputLabel">Restaurant Address {requireSign}</label>
                  <textarea
                    className="inputField"
                    style={errors.restaurant_address && errorInputStyle}
                    {...register('restaurant_address', {
                      required: true,
                    })}
                  />
                </div>
                <div className="d-flex flex-row align-items-center ml-5">
                  <label className="inputLabel">About Restaurant {requireSign}</label>
                  <textarea
                    className="inputField"
                    style={errors.restaurant_description && errorInputStyle}
                    {...register('restaurant_description', {
                      required: true,
                    })}
                  />
                </div>
              </div>
            </div>
            <div className="mt-3">
              <div className="d-flex flex-row">
                <div className="d-flex flex-row align-items-center">
                  <label htmlFor="country" className="inputLabel">
                    Country {requireSign}
                  </label>
                  <select
                    className="selectOptions form-control"
                    {...register('country', {
                      required: true,
                    })}
                  >
                    <option value="UAE">UAE</option>
                  </select>
                </div>
                <div className="d-flex flex-row align-items-center">
                  <label htmlFor="city" className="inputLabel" style={{ width: '50px', marginLeft: '20px' }}>
                    City {requireSign}
                  </label>
                  <select
                    className="selectOptions form-control"
                    {...register('city', {
                      required: true,
                    })}
                  >
                    <option value="Dubai">Dubai</option>
                  </select>
                </div>
                <div className="d-flex flex-row align-items-center">
                  <label htmlFor="area" className="inputLabel" style={{ width: '50px', marginLeft: '20px' }}>
                    Area {requireSign}
                  </label>
                  <select
                    className="selectOptions form-control"
                    {...register('area', {
                      required: true,
                    })}
                  >
                    <option value="Palm Jumeirah">Palm Jumeirah</option>
                    <option value="Marina">Marina</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="mt-3">
              <div className="d-flex flex-row">
                <div className="d-flex flex-row align-items-center">
                  <label className="inputLabel">Website</label>
                  <div className="d-flex flex-row align-items-center">
                    <input
                      type="text"
                      className="inputField"
                      placeholder=""
                      style={{ width: '200px' }}
                      {...register('website_url')}
                    />
                  </div>
                </div>
                <div className="d-flex flex-row align-items-center">
                  <label className="inputLabel" style={{ textAlign: 'center' }}>
                    Facebook
                  </label>
                  <div className="d-flex flex-row align-items-center">
                    <input
                      type="text"
                      className="inputField"
                      placeholder=""
                      style={{ width: '200px' }}
                      {...register('facebook_url')}
                    />
                  </div>
                </div>
                <div className="d-flex flex-row align-items-center">
                  <label className="inputLabel" style={{ textAlign: 'center' }}>
                    Instagram
                  </label>
                  <div className="d-flex flex-row align-items-center">
                    <input
                      type="text"
                      className="inputField"
                      placeholder=""
                      style={{ width: '200px' }}
                      {...register('instagram_url')}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3">
              <div className="d-flex flex-row">
                <div className="d-flex flex-row align-items-center">
                  <label className="inputLabel">TRN License {requireSign}</label>
                  <div className="d-flex flex-row align-items-center">
                    <input
                      type="text"
                      className="inputField"
                      style={{ width: '200px', ...(errors.trn_license && errorInputStyle) }}
                      {...register('trn_license', {
                        required: true,
                      })}
                    />
                  </div>
                </div>
                <div className="d-flex flex-row align-items-center">
                  <label className="inputLabel" style={{ textAlign: 'center' }}>
                    VAT {requireSign}
                  </label>
                  <div className="d-flex flex-row align-items-center">
                    <input
                      type="number"
                      className="inputField"
                      style={{ width: '200px', ...(errors.vat && errorInputStyle) }}
                      {...register('vat', {
                        valueAsNumber: true,
                        required: true,
                      })}
                    />
                  </div>
                </div>
                <div className="d-flex flex-row align-items-center justify-content-center w-100">
                  <label className="switch mr-3 mb-1">
                    <input
                      type="checkbox"
                      {...register('is_vat_included', {
                        value: false,
                      })}
                    />
                    <span className="slider round"></span>
                  </label>
                  <label className="inputLabel" style={{ textAlign: 'center', marginRight: '50px' }}>
                    VAT already included in price
                  </label>
                </div>
              </div>
            </div>
            <div className="mt-5 mb-3">
              {isNewRestaurant ? (
                <button onClick={handleSubmit(createOnSubmit)} className="submitButton">
                  Create
                </button>
              ) : (
                <button onClick={handleSubmit(updateOnSubmit)} className="submitButton">
                  Update
                </button>
              )}
              <button onClick={(): void => setShow(false)} className="cancelButton ml-2">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default RestaurantModal;
