import { initializeApp } from 'firebase/app';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { getMessaging, getToken } from 'firebase/messaging';
import { connectStorageEmulator, getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_authDomain,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
const auth = getAuth(app);
auth.tenantId = process.env.REACT_APP_TENANT_ID!;

const getDeviceToken = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      const currentToken = await getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY });
      if (currentToken) {
        return await Promise.resolve(currentToken);
      }
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
getDeviceToken();
if (process.env.REACT_APP_USE_EMULATOR === 'true') {
  connectAuthEmulator(getAuth(app), 'http://localhost:9099');
  connectFirestoreEmulator(getFirestore(app), 'localhost', 8080);
  connectStorageEmulator(getStorage(app), 'localhost', 9199);
} else {
  getAuth(app);
  getFirestore(app);
  getStorage(app);
}

export { auth };
