import { useState } from 'react';
import { login } from '../../../services/auth';
import ForgetPassword from './forgetPass';

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [body, setBody] = useState({
    email: '',
    password: '',
    returnSecureToken: 'true',
  });
  const loadingBtnStyle = { padding: '13px', backgroundColor: '#cccccc' };
  const loginHandler = (e: any) => {
    e.preventDefault();
    setLoading(true);
    login(body).then(() => {
      setLoading(false);
    });
  };
  const [forgetPass, setForgetPass] = useState(false);
  return (
    <>
      {forgetPass && <ForgetPassword show={forgetPass} setShow={setForgetPass} />}
      <div className="p-2 d-flex justify-content-center">
        <div
          className="col "
          style={{
            paddingLeft: '1.5em',
            backgroundColor: '#E5E5E5',
          }}
        >
          <div style={{ height: '95vh' }} className="row">
            <div className="col-lg-12 col-md-12 u-orderlefttable">
              <h2 className="mt-5 pt-5 u-login0011 mb-3">
                RESTRO
                <h4>Superadmin</h4>
              </h2>
              <form className="signup" onSubmit={loginHandler}>
                <div className="form-group">
                  <label className="u-userlogin001">Username</label>
                  <input
                    onChange={(e) => setBody({ ...body, email: e.target.value })}
                    style={{
                      backgroundColor: '#F9F9F9',
                      borderRadius: '10px',
                      border: 'none',
                      height: '57px',
                      fontSize: '24px',
                      fontFamily: 'roboto',
                    }}
                    type="email"
                    required
                    className="form-control u-formlogin001"
                  />
                </div>
                <div className="form-group">
                  <label className="u-userlogin001">Password</label>
                  <input
                    onChange={(e) => setBody({ ...body, password: e.target.value })}
                    style={{
                      backgroundColor: '#F9F9F9',
                      borderRadius: '10px',
                      border: 'none',
                      height: '57px',
                      fontSize: '24px',
                      fontFamily: 'roboto',
                    }}
                    type="password"
                    className="form-control"
                  />
                </div>
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <input
                      style={{ height: '15px' }}
                      className="mt-2"
                      type="checkbox"
                      id="signin"
                      name="signin"
                      value="checkbox"
                    />
                    <label className="u-checkboxlogin001 ml-2">Stay signed in</label>
                    <br />
                  </div>
                  <p onClick={() => setForgetPass(true)} className="u-checkboxlogin001 " style={{ cursor: 'pointer' }}>
                    Forgot Password?
                  </p>
                </div>

                <button disabled={loading} style={loading ? loadingBtnStyle : {}} className="u-loginbutton001 mt-3">
                  Log In
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
