import { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { logout } from '../../../services/auth';
import { getCurrentUser } from '../../../utils/getCurrentUser';
import { images } from '../exports';

const Header = () => {
  const [user, setUser] = useState<any>(getCurrentUser());
  const { lock, bell, bell2, bestrouser, menu, searchNormal } = images;
  const logoutHandler = () => {
    logout();
  };

  return (
    <nav
      style={{
        backgroundColor: '#fff',
        position: 'fixed',
        zIndex: '1',
        left: '0',
        right: '0',
      }}
      className="sb-topnav navbar navbar-expand navbar-blue bg-blue shadow py-3"
    >
      <div className="form-group has-search mt-3" style={{ marginLeft: '150px' }}>
        <span>
          <img
            style={{
              position: 'absolute',
              marginTop: '11px',
              marginLeft: '12px',
              cursor: 'pointer',
            }}
            src={searchNormal}
            alt=""
          />
        </span>
        <input type="text" className="ml-2 form-control pl-4 u-search-menu001" />
      </div>

      <div className="d-flex flex-row align-items-center ml-auto">
        <div className="d-flex align-items-center">
          <div className="mr-3 margin" style={{ fontWeight: '500', fontSize: '16px' }}>
            {user?.displayName ?? 'Super Admin'}
          </div>
        </div>
        <Dropdown>
          <Dropdown.Toggle variant="" id="dropdown-basic">
            <img style={{ position: 'relative' }} src={bell} alt="" />
          </Dropdown.Toggle>

          <Dropdown.Menu
            style={{
              boxShadow: '10px 10px 30px grey',
              maxHeight: '600px',
              overflowY: 'scroll',
            }}
          >
            <Dropdown.Item>
              <div className="pl-5 pr-5 pb-1 pt-1" style={{ border: '1px solid #E5E5E5' }}>
                <span> No Notifications</span>
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown>
          <Dropdown.Toggle
            style={{
              outline: 'none !important',
              boxShadow: 'none',
              border: 'none',
            }}
            variant="success"
            id="dropdown-basic"
          >
            <img src={bestrouser} alt="" />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <div onClick={logoutHandler}>
              <Dropdown.Item>Logout</Dropdown.Item>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </nav>
  );
};

export default Header;
