import { Link } from 'react-router-dom';
import svg from '../../../assets/Images/background.svg';
import BrowserRoutes from '../../../routes/BrowserRoutes';
import { images } from '../exports';

const SideBar = () => {
  const { RESTAURANTS, DASHBOARD, USERS, MARKETING, ISSUES, CUSTOMERS, ACCOUNTING } = BrowserRoutes;
  const { R, line } = images;
  const sideBarData = [
    {
      id: 1,
      text: 'Dashboard',
      link: DASHBOARD,
      innerItems: [],
    },
    {
      id: 2,
      text: 'Restaurants',
      link: RESTAURANTS,
      innerItems: [],
    },
    // {
    //   id: 3,
    //   text: 'Customers',
    //   link: CUSTOMERS,
    //   innerItems: [],
    // },
    // {
    //   id: 4,
    //   text: 'Marketing',
    //   link: `${MARKETING}/campaigns`,
    //   innerItems: [
    //     { id: 1, title: 'Campaigns', link: `${MARKETING}/campaigns` },
    //     { id: 2, title: 'Flash Sales', link: `${MARKETING}/flashsales` },
    //     { id: 3, title: 'Restro', link: `${MARKETING}/restro` },
    //   ],
    // },
    // {
    //   id: 5,
    //   text: 'Accounting',
    //   link: ACCOUNTING,
    //   innerItems: [],
    // },
    // {
    //   id: 6,
    //   text: 'Issues',
    //   link: ISSUES,
    //   innerItems: [],
    // },
    {
      id: 7,
      text: 'Users',
      link: USERS,
      innerItems: [],
    },
  ];
  const selectedStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    color: 'white',
  };
  const selectedStyle2 = {
    position: 'absolute',
    top: '50%',
    left: '70%',
    color: 'white',
  };

  return (
    <nav className="sb-sidenav accordion" id="sidenavAccordion">
      <div className="sb-sidenav-menu">
        <div className="nav">
          <div className="sb-sidenav-menu-heading"></div>
          <div className="mb-3 pt-2 pb-2">
            <div className="d-flex justify-content-center">
              <img src={R} alt="" />
            </div>

            <div className="d-flex justify-content-center mt-2">
              <img src={line} alt="" />
            </div>
          </div>
          {sideBarData?.map((d: any) => (
            <div key={d.id} className="px-2 pt-3 pb-3">
              <Link style={{ textDecoration: 'none' }} to={d.link}>
                <div className="d-flex justify-content-center" style={{ cursor: 'pointer' }}></div>
                <div
                  style={{
                    position: 'relative',
                    textAlign: 'center',
                    color: 'white',
                  }}
                >
                  {window.location.href.split('/')[3] === d.link.split('/')[1] ? (
                    <>
                      <img src={svg} alt="" style={{ marginLeft: '-10px' }} />
                      <span className="sidemenuparagraph" style={selectedStyle as any}>
                        {d.text}
                      </span>
                    </>
                  ) : (
                    <span className="sidemenuparagraph"> {d.text}</span>
                  )}
                </div>
                {window.location.href.split('/')[3] === d.link.split('/')[1] &&
                  d?.innerItems?.map((i: any) => (
                    <Link key={i.id} style={{ textDecoration: 'none' }} to={i.link}>
                      <div
                        className="mb-2"
                        style={{
                          textAlign: 'center',
                          position: 'relative',
                          color: 'white',
                        }}
                      >
                        {window.location.href.split('/')[4] === i.link.split('/')[2] ? (
                          <>
                            {' '}
                            <img src={svg} alt="" style={{ position: 'relative', left: '18px' }} />
                            <span style={selectedStyle2 as any} key={i.id} className="sidemenuparagraph mr-2">
                              {i.title}
                            </span>
                          </>
                        ) : (
                          <span key={i.id} className="sidemenuparagraph mr-2">
                            {i.title}
                          </span>
                        )}
                      </div>
                    </Link>
                  ))}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default SideBar;
