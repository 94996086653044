const Ratings = (props: any) => {
  const { star, number, ratings } = props;
  return (
    <div>
      <div className="side ">
        <div>{star} star</div>
      </div>
      <div className="middle">
        <div className="progress" style={{ width: '100%' }}>
          <div
            className="progress-bar"
            style={{
              width: `${
                ((ratings?.average_rating?.[`${number}_star`] ?? 0) / (ratings?.average_rating?.total_reviews ?? 0)) *
                100
              }%`,
              backgroundColor: '#834bff',
            }}
            role="progressbar"
            aria-valuenow={30}
            aria-valuemin={0}
            aria-valuemax={ratings?.average_rating?.total_reviews ?? 0}
          ></div>
        </div>
      </div>
      <div className="side right">
        <div>{ratings?.average_rating?.[`${number}_star`] ?? 0}</div>
      </div>
    </div>
  );
};

export default Ratings;
