import { useState } from 'react';
import DashboardComponent from '../../dashboardComponent/dashboardComponent';
import ItemsRatings from './subComponents/itemsRatings';
import RestaurantRatings from './subComponents/restaurantRatings';
import ReviewDetails from './subComponents/reviewDetails';
import TopRatedRestaurants from './subComponents/topRatedRestaurants';

const CustomerSatisfaction = () => {
  const ratings = [] as any;
  const VariantRatings = [] as any;
  const [data, setData] = useState([] as any);
  const [dataType, setDataType] = useState('');
  return (
    <>
      <div className="col-xl-9 col-md-12  mt-3 mb-4" style={{ height: '100%' }}>
        <div className="col-xl-12 pr-0">
          {<RestaurantRatings ratings={ratings} setData={setData} setDataType={setDataType} />}
          {<TopRatedRestaurants ratings={VariantRatings} setData={setData} setDataType={setDataType} />}
          {<ItemsRatings ratings={VariantRatings} setData={setData} setDataType={setDataType} />}
        </div>
      </div>
      {data?.length ? (
        <ReviewDetails data={data} setData={setData} dataType={dataType} />
      ) : (
        <DashboardComponent fromHome={true} />
      )}
    </>
  );
};

export default CustomerSatisfaction;
