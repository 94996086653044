import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import BrowserRoutes from '../../../routes/BrowserRoutes';
import { useRestaurantStore } from '../../../store';

interface PropType {
  component: React.FC;
}

const AuthRoute: FC<PropType> = ({ component: Component }) => {
  const { loggedin } = useRestaurantStore((state) => state);
  if (loggedin) {
    return <Navigate to={BrowserRoutes.DASHBOARD} />;
  }
  return <Component />;
};

export default AuthRoute;
