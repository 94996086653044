export const serverUrl = process.env.REACT_APP_BASE_URL;
const ServerRoutes = {
  GET_CUISINES: () => `api/cuisines/`,
  CREATE_CUISINE: () => `api/cuisines/`,
  GET_AMBIANCES: () => `api/ambiances/`,
  CREATE_AMBIANCES: () => `api/ambiances/`,
  GET_RESTAURANTS: () => `api/restaurants/`,
  CREATE_RESTAURANT: () => `api/restaurants/`,
  UPDATE_RESTAURANT: (restaurantId: string) => `api/restaurants/${restaurantId}`,
  UPDATE_USER: (userId: string) => `api/users/${userId}`,
  CREATE_USER: `api/users/`,
  GET_USERS: `api/users/?type=admin`,
};
export default ServerRoutes;
