import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';
interface RestaurantState {
  loggedin: boolean;
  setLoggedin: (value: boolean) => void;
}
export const useRestaurantStore = create<RestaurantState>()(
  devtools(
    persist(
      (set) => ({
        loggedin: false,
        setLoggedin: (value) => set((state) => ({ ...state, loggedin: value })),
      }),
      {
        name: 'restuarant-storage',
      },
    ),
  ),
);

export const storeState = useRestaurantStore.getState();
