import React, { useState } from 'react';
import { Bar, CartesianGrid, ComposedChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

const BarChart = (props: any) => {
  const { x, y1, y2 } = props;
  const [maximum, setMaximum] = useState(100);

  let tooltip: any;
  const CustomTooltip = ({ active, payload }: any) => {
    if (!active || !tooltip) return null;
    for (const bar of payload)
      if (bar.dataKey === tooltip)
        return props.heading === 'Customers' || props.heading === 'Restaurants' ? (
          <div
            style={{
              background: '#FFFFFF',
              boxShadow: '0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06)',
              borderRadius: '15px',
            }}
            className="p-3"
          >
            <svg
              className="mr-2"
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="5" cy="5" r="5" fill="#34D959" />
            </svg>
            <span style={{ color: '#979797' }}>{((bar.value.toFixed(2) * 100) / maximum).toFixed(0)}%</span>
            <br />
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: '600',
              }}
            >
              {`${bar.value} ${props.heading}`}
            </span>
            <br />
            {payload?.[0]?.payload.av && (
              <span style={{ color: '#7058E1', fontWeight: '400' }}>
                {props.heading === 'Customers' ? 'TICKET SIZE' : 'Av sales'} {` ${payload?.[0]?.payload.av} `} AED
              </span>
            )}
          </div>
        ) : (
          <div
            style={{
              background: '#FFFFFF',
              boxShadow: '0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06)',
              borderRadius: '15px',
            }}
            className="p-3"
          >
            <span style={{ color: '#979797' }}>Today</span>
            <br />
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: '600',
              }}
            >
              {bar.value} AED
            </span>
            <br />
          </div>
        );
    return null;
  };
  return (
    <React.Fragment>
      <span className="d-block pb-4 m-2 u-saleflash001">#{props.heading}</span>
      <ResponsiveContainer width="100%" height={270}>
        <ComposedChart width={1300} height={350} data={props.data}>
          <Legend
            iconSize={10}
            iconType={'circle'}
            width={170}
            height={320}
            layout="horizontal"
            align="right"
            verticalAlign="middle"
          />
          <XAxis
            dataKey={x}
            axisLine={{ stroke: '#EAF0F4' }}
            style={{
              fontSize: 'small',
              opacity: '1',
              color: 'purple',
            }}
          />
          <YAxis
            yAxisId="right-axis"
            orientation="right"
            tickCount={6}
            axisLine={{ stroke: '#EAF0F4' }}
            style={{ fontSize: 'small', opacity: '0' }}
          />
          <YAxis
            tickCount={6}
            unit={props.unit}
            axisLine={{ stroke: '#EAF0F4' }}
            style={{ fontSize: 'small', opacity: '1' }}
          />
          <CartesianGrid strokeDasharray="6 6" vertical={false} stroke="#DDD" />

          <Tooltip content={<CustomTooltip />} />
          <Bar yAxisId="right-axis" dataKey={y1} barSize={40} fill="#D4C0FF" />
          <Bar
            type="monotone"
            dataKey={y2}
            stroke="#834BFF"
            barSize={40}
            fill="#9A6CFF"
            onMouseOver={() => (tooltip = y1)}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
};

export default BarChart;
