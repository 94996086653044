import LinkIcon from '@mui/icons-material/Link';
import { useEffect, useState } from 'react';
import { BounceLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import useSWR from 'swr';
import withMainLayout from '../../components/global/HOC/withMainLayout';
import RestaurantSelect from '../../components/global/restaurantSelect/restaurantSelect';
// import AddEditModal from '../../components/local/restaurants/modals/modal';
import { RestaurantAttributes } from '../../constants/constants';
import ServerRoutes from '../../routes/ServerRoutes';
import { updateRestaurant } from '../../services/restaurant';
import RestaurantModal from './components/restaurant-modal';

const RestaurantScreen: React.FC = () => {
  const [restaurantState, setRestaurant] = useState({});
  const [filteredRestaurants, setFilteredRestaurants] = useState<any[]>([]);
  const { data: restaurants, isLoading, error } = useSWR(ServerRoutes.GET_RESTAURANTS());
  const [date] = useState(new Date());

  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    setFilteredRestaurants(restaurants);
  }, [restaurants]);

  const handleAddModal = (): void => {
    setRestaurant({});
    setShowModal(!showModal);
  };
  if (isLoading) {
    return (
      <div className="loader">
        <BounceLoader color="#834bff" />
      </div>
    );
  }
  if (error) {
    return (
      <div className="loader">
        <BounceLoader color="#834bff" />
      </div>
    );
  }
  return (
    <>
      {showModal && <RestaurantModal restaurant={restaurantState} show={showModal} setShow={setShowModal} />}
      <div className="p-3 mt-5">
        <div className="d-flex col-lg-12 col-xl-12 col-md-12 u-orderlefttable py-4 px-0 justify-content-end bg-transparent">
          <RestaurantSelect filter={setFilteredRestaurants} restaurants={restaurants} />
          <button className="u-menubutton001 ml-3" onClick={handleAddModal}>
            + Add Restaurant
          </button>
        </div>
        <div className="col-12 u-orderlefttable p-3">
          <h2 className="u-orderheading ">Restaurants</h2>
          <div className="table-responsive">
            <table className="table">
              <thead className="u-tablemenuheding">
                <tr>
                  {RestaurantAttributes?.map((RestaurantAttribute) => (
                    <th key={RestaurantAttribute.id} className="u-ordertablemainhead" scope="col">
                      {RestaurantAttribute.title}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredRestaurants?.map((restaurant) => (
                  <tr key={restaurant.id}>
                    <td
                      onClick={(): void => {
                        setRestaurant(restaurant);
                        setShowModal(true);
                      }}
                      className="u-numberrowwwwwn"
                    >
                      {restaurant.restaurant_name || restaurant.name}
                    </td>
                    <td className="u-numberrowwwww">{restaurant.email || restaurant.restaurant_email || '-'}</td>
                    <td className="u-numberrowwwww">{restaurant.restaurant_phone || '-'}</td>
                    <td className="u-numberrowwwww">
                      {restaurant.address?.city || `${restaurant.area} ${restaurant.city}, ${restaurant.country}`}
                    </td>
                    <td className="u-numberrowwwww">
                      <div className="d-flex justify-content-center">
                        <label className="switch">
                          <input
                            onChange={(e): void => {
                              updateRestaurant(restaurant.id, { is_active: e.target.checked });
                              toast.success('Restaurant Updated Successfully');
                            }}
                            type="checkbox"
                            defaultChecked={restaurant.is_active}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </td>
                    <td className="u-numberrowwwww">
                      {restaurant.monthly_sales?.[date.getFullYear()]?.[date.getMonth() + 1] ?? 0} AED
                    </td>
                    <td className="u-numberrowwwww">
                      {restaurant.monthly_orders?.[date.getFullYear()]?.[date.getMonth() + 1] ?? 0}
                    </td>
                    <td className="u-numberrowwwww">
                      {restaurant.deep_link ? (
                        <LinkIcon
                          color="info"
                          fontSize="large"
                          titleAccess="copy deep link"
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={() => navigator.clipboard.writeText(restaurant.deep_link)}
                        />
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default withMainLayout(RestaurantScreen);
