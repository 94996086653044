import { useState } from 'react';
import { images } from '../../global/exports';
import withMainLayout from '../../global/HOC/withMainLayout';
import RestaurantSelect from '../../global/restaurantSelect/restaurantSelect';
import CustomerSatisfaction from './tabs/customerSatisfaction/customerSatisfaction';
import Marketing from './tabs/marketing/marketing';
import RestroMarketing from './tabs/restroMarketing/restroMarketing';
import Sales from './tabs/sales/sales';

const Dashboard = () => {
  const { calendar, downArrow } = images;
  const [tab, setTab] = useState('SALES');
  const [fromDate, setFromDate] = useState(new Date(new Date().setDate(new Date().getDate() - 30)));
  const [loading, setLoading] = useState(false);
  const [toDate, setToDate] = useState(new Date());
  const selectedTabClass = 'u-tabsbutton23';
  const notSelected = 'u-tabsbuttonss230';
  const [restId, setRestId] = useState('');
  const selectFilter = (e: any) => {
    if (e.target.value === 'ALL') {
      setRestId('');
    } else {
      setRestId(e.target.value);
    }
  };

  const handleChangeDate = () => {};
  return (
    <div
      style={{
        paddingTop: '20px',
        backgroundColor: '#E5E5E5',
      }}
      className="mt-3"
    >
      <h2 style={{ marginTop: '70px' }} className="u-orderheading "></h2>
      <div className="d-flex flex-wrap justify-content-between col-xl-9 col-lg-12 col-md-12 p-0 pr-0 pl-3 align-item-center">
        <div className="d-flex justify-content-between p-0 mt-2">
          <div className="u-tabsclassmenu2332">
            <ul className="d-flex flex-row align-items-center justify-content-between pl-0 mb-0 p-1">
              <li
                style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                onClick={() => setTab('SALES')}
                className={tab === 'SALES' ? selectedTabClass : notSelected}
              >
                Sales
              </li>
              <li
                style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                onClick={() => setTab('MARKETING')}
                className={tab === 'MARKETING' ? `${selectedTabClass}` : `${notSelected}`}
              >
                MARKETING
              </li>
              <li
                style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                onClick={() => setTab('RMARKETING')}
                className={tab === 'RMARKETING' ? `${selectedTabClass}` : `${notSelected}`}
              >
                RESTRO MARKETING
              </li>
              <li
                style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                onClick={() => setTab('CST')}
                className={tab === 'CST' ? `${selectedTabClass}` : `${notSelected}`}
              >
                CUSTOMER SATISFACTION
              </li>
            </ul>
          </div>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-between mt-2">
          <RestaurantSelect filter={selectFilter} name={'All Restaurants'} defaultValue={'ALL'} />
          <div className="u-tabsclassmenu2330001"></div>
        </div>
      </div>
      <div className="row pl-3">
        {tab === 'SALES' && <Sales loading={loading} fromDate={fromDate} toDate={toDate} />}
        {tab === 'CST' && <CustomerSatisfaction />}
        {tab === 'MARKETING' && <Marketing fromDate={fromDate} toDate={toDate} />}
        {tab === 'RMARKETING' && <RestroMarketing fromDate={fromDate} toDate={toDate} />}
      </div>
    </div>
  );
};

export default withMainLayout(Dashboard);
