import Dialog from '@mui/material/Dialog';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-number-input';
import { BounceLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import ServerRoutes from 'routes/ServerRoutes';
import { createUser, updateUser } from 'services/auth';
import { User } from 'src/@types/user';
import { mutate } from 'swr';
import { z } from 'zod';

const errorInputStyle = { borderStyle: 'solid', borderColor: 'red' };

const UserModal = ({ show, setShow, user }: any) => {
  const isNewUser = Object.keys(user || {}).length === 0;
  const [loading, setLoading] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm<User>(
    !isNewUser
      ? {
          defaultValues: {
            ...user,
          },
        }
      : {
          defaultValues: {
            type: 'admin',
          },
        },
  );

  const createOnSubmit: SubmitHandler<User> = async (data) => {
    setLoading(true);
    try {
      const response = await createUser(data);
      toast.success('User created successfully');
      mutate(ServerRoutes.GET_USERS);
    } catch (error: any) {
      toast.error(error?.message);
      console.log(error);
    }
    setLoading(false);
    setShow(false);
  };

  const updateOnSubmit: SubmitHandler<User> = async (data) => {
    setLoading(true);
    try {
      const response = await updateUser(user.id, data);
      toast.success('User updated successfully');
      mutate(ServerRoutes.GET_USERS);
    } catch (error: any) {
      toast.error(error?.message);
      console.log(error);
    }
    setLoading(false);
    setShow(false);
  };

  return (
    <Dialog open={show} fullWidth={true} maxWidth="lg">
      <div className="loader">
        <BounceLoader loading={loading} color="#834bff" />
      </div>
      <div className="modalBody">
        <div className="modalHeader">
          <h5 className="modalHeading mt-2" id="exampleModalLabel">
            User Information
          </h5>
          <svg
            onClick={() => setShow(false)}
            className="modalCloseButton"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.9664 10.3596L10.3598 20.9662C9.99744 21.3286 9.3964 21.3286 9.03401 20.9662C8.67161 20.6038 8.67161 20.0028 9.03401 19.6404L19.6406 9.03377C20.003 8.67138 20.604 8.67138 20.9664 9.03377C21.3288 9.39616 21.3288 9.9972 20.9664 10.3596Z"
              fill="#292D32"
            />
            <path
              d="M20.9664 20.9662C20.604 21.3286 20.003 21.3286 19.6406 20.9662L9.03401 10.3596C8.67161 9.99724 8.67161 9.3962 9.03401 9.0338C9.3964 8.67141 9.99744 8.67141 10.3598 9.0338L20.9664 19.6404C21.3288 20.0028 21.3288 20.6038 20.9664 20.9662Z"
              fill="#292D32"
            />
          </svg>
        </div>
        <div>
          <div className="mt-2">
            <div className="d-flex flex-row">
              <div className="d-flex flex-row align-items-center mt-5">
                <label className="inputLabel">First Name</label>
                <input
                  type="text"
                  className="inputField"
                  style={errors.first_name && errorInputStyle}
                  {...register('first_name', { required: true })}
                />
              </div>
              <div className="d-flex flex-row align-items-center ml-5 mt-5">
                <label className="inputLabel">Last Name</label>
                <input
                  type="text"
                  className="inputField"
                  style={errors.last_name && errorInputStyle}
                  {...register('last_name', { required: true })}
                />
              </div>
            </div>
          </div>
          <div className="mt-3">
            <div className="d-flex flex-row">
              <div className="d-flex flex-row align-items-center">
                <label className="inputLabel">Contact Number</label>
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <PhoneInput
                      className="inputField"
                      style={errors.phoneNumber && errorInputStyle}
                      value={value}
                      onChange={onChange}
                      defaultCountry="AE"
                      id="phoneNumber"
                    />
                  )}
                />
              </div>
              <div className="d-flex flex-row align-items-center ml-5">
                <label className="inputLabel">Email Address</label>
                <input
                  {...(!isNewUser ? { disabled: true } : { disabled: false })}
                  type="email"
                  className="inputField"
                  style={errors.email && errorInputStyle}
                  {...register('email', { required: true, validate: (v) => z.string().email().safeParse(v).success })}
                />
              </div>
            </div>
          </div>

          <div className="mt-3">
            <div className="d-flex flex-row">
              <div className="d-flex flex-row align-items-center">
                <label className="inputLabel">Address</label>
                <textarea className="inputField" {...register('address')} />
              </div>
              <div className="d-flex flex-row align-items-center ml-5">
                <label className="inputLabel">Role</label>
                <div style={{ marginLeft: 0 }}>
                  <select className="inputField" {...register('role', { required: true })}>
                    <option value="admin">Admin</option>
                    <option value="user">User</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5 mb-5">
            <div className="d-flex flex-row">
              <div className="d-flex flex-row align-items-center">
                <label className="inputLabel">Country</label>
                <div className="selectOptions form-control">
                  <select {...register('country')}>
                    <option value="UAE">UAE</option>
                  </select>
                </div>
              </div>
              <div className="d-flex flex-row align-items-center">
                <label className="inputLabel" style={{ width: '100px', marginLeft: '30px' }}>
                  City
                </label>
                <div className="selectOptions form-control" style={{ marginLeft: 0 }}>
                  <select {...register('city')}>
                    <option value="Dubai">Dubai</option>
                  </select>
                </div>
              </div>
              <div className="d-flex flex-row align-items-center">
                <label className="inputLabel" style={{ width: '100px', marginLeft: '30px' }}>
                  Area
                </label>
                <div className="selectOptions form-control" style={{ marginLeft: 0 }}>
                  <select {...register('area')}>
                    <option value="Palm Jumeirah">Palm Jumeirah</option>
                    <option value="Marina">Marina</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5 mb-3">
            {isNewUser ? (
              <button onClick={handleSubmit(createOnSubmit)} className="submitButton">
                Create
              </button>
            ) : (
              <button onClick={handleSubmit(updateOnSubmit)} className="submitButton">
                Update
              </button>
            )}
            <button onClick={() => setShow(false)} className="cancelButton ml-2">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default UserModal;
