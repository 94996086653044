import { images } from '../../../global/exports';

const DashboardComponent = (props: any) => {
  const { group501, group499, statusUp } = images;
  return (
    <div className="col-lg-12 col-xl-3 col-md-12 ml-auto mb-4">
      <div className="u-orderlefttabledashbord001">
        <h2 className="u-menuflashsale00111 mb-3 mt-5">Dashboard</h2>
        <div className="row mt-5">
          <div className="col-lg-6 col-md-6 px-1">
            <div className="u-mainslashsalecont002 py-3 px-3 h-100 d-flex flex-column justify-content-between">
              <p className="u-45644">Gross Sales of Restaurants</p>
              <p className="u-slashsale0099">1200 AED</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 px-1 mt-md-0 mt-lg-0">
            <div className="u-mainslashsalecont002 py-3 px-3 h-100 d-flex flex-column justify-content-between">
              <p className="u-45644">Payments by Restro</p>
              <p className="u-slashsale0099">1200 AED</p>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-lg-6 col-md-6 px-1">
            <div className="u-mainslashsalecont003 py-3 px-3 h-100 d-flex flex-column justify-content-between">
              <p className="u-45644"># Orders</p>
              <p className="u-slashsale0099">1200</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 px-1 mt-md-0 mt-lg-0">
            <div className="u-mainslashsalecont003 py-3 px-3 h-100 d-flex flex-column justify-content-between">
              <p className="u-45644"># Active Restaurants</p>
              <p className="u-slashsale0099">1200</p>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-lg-6 col-md-6 px-1">
            <div className="u-mainslashsalecont004 py-3 px-3 h-100 d-flex flex-column justify-content-between">
              <p className="u-45644"># Active Customers</p>
              <p className="u-slashsale0099">1200</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 px-1 mt-md-0 mt-lg-0">
            <div className="u-mainslashsalecont004 py-3 px-3 h-100 d-flex flex-column justify-content-between">
              <p className="u-45644">Av Order/Customer</p>
              <p className="u-slashsale0099">1200</p>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-lg-6 col-md-6 px-1">
            <div className="u-mainslashsalecont005 py-3 px-3 h-100 d-flex flex-column justify-content-between">
              <p className="u-45644"># Campaign Messages </p>
              <p className="u-slashsale0099">1200</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 px-1 mt-md-0 mt-lg-0">
            <div className="u-mainslashsalecont005 py-3 px-3 h-100 d-flex flex-column justify-content-between">
              <p className="u-45644">Conversion Rate</p>
              <p className="u-slashsale0099">1200 %</p>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-lg-6 col-md-6 px-1">
            <div className="u-mainslashsalecont005 py-4 px-4 h-100 d-flex flex-column justify-content-between">
              <p className="u-45644">Sales from Campaigns </p>
              <p className="u-slashsale0099">1200 AED</p>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-lg-6 col-md-6 px-1">
            <div className="u-mainslashsalecont006 py-3 px-3 h-100 d-flex flex-column justify-content-between">
              <p className="u-45644">Restro Rev. </p>
              <p className="u-slashsale0099">1200 AED</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 px-1 mt-md-0 mt-lg-0">
            <div className="u-mainslashsalecont006 py-3 px-3 h-100 d-flex flex-column justify-content-between">
              <p className="u-45644">Restro Disc Costs</p>
              <p className="u-slashsale0099">1200 AED</p>
            </div>
          </div>
        </div>
        {props.fromHome ? (
          ''
        ) : (
          <div className="u-mainslashsalecont004 mt-5">
            <img className="mb-3" src={group501} />

            <p className="u-slashsale0099">200 %</p>
            <text className="u-45644">ROAS </text>
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardComponent;
