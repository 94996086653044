import { AxiosResponse } from 'axios';
import { Restaurant } from 'src/@types/restaurant';
import ServerRoutes from '../routes/ServerRoutes';
import { apiRequest } from '../utils/axios';

export const createRestaurant = async (body: Restaurant): Promise<AxiosResponse> =>
  apiRequest(ServerRoutes.CREATE_RESTAURANT(), 'post', body);

export const updateRestaurant = async (restaurantId: string, body: Partial<Restaurant>): Promise<AxiosResponse> =>
  apiRequest(ServerRoutes.UPDATE_RESTAURANT(restaurantId), 'patch', body);
