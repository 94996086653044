import { images } from 'components/global/exports';
import withMainLayout from 'components/global/HOC/withMainLayout';
import { UsersTable } from 'constants/constants';
import { useState } from 'react';
import { BounceLoader } from 'react-spinners';
import ServerRoutes from 'routes/ServerRoutes';
import useSWR from 'swr';
import UserModal from './components/user-modal';

const UserScreen = () => {
  const { data: users, isLoading, error } = useSWR(ServerRoutes.GET_USERS);
  const [user, setUser] = useState({});

  const [showAddEditModal, setShowAddEditModal] = useState(false);

  const handleAddEditModal = (d: any) => {
    setUser({});
    setShowAddEditModal(!showAddEditModal);
  };

  if (isLoading) {
    return (
      <div className="loader">
        <BounceLoader color="#834bff" />
      </div>
    );
  }
  if (error) {
    return (
      <div className="loader">
        <BounceLoader color="#834bff" />
      </div>
    );
  }
  return (
    <>
      {showAddEditModal && <UserModal user={user} show={showAddEditModal} setShow={setShowAddEditModal} />}

      <div
        style={{
          marginTop: '70px',
        }}
        className="p-3"
      >
        <div className="col-lg-12 col-xl-12 col-md-12 u-orderlefttable p-3">
          <div
            className="d-flex mb-2"
            style={{
              backgroundColor: 'transparent',
              justifyContent: 'end',
            }}
          >
            <span
              style={{ cursor: 'pointer' }}
              className="u-menubutton001 pt-2 pb-2"
              onClick={() => handleAddEditModal(user)}
            >
              + Add
            </span>
          </div>
          <div style={{ maxHeight: '70vh' }} className="table-responsive">
            <table className="table">
              <thead className="u-tablemenuheding">
                <tr style={{ textAlign: 'center' }}>
                  {UsersTable.map((d) => (
                    <th key={d.id} className="u-ordertablemainhead" style={{ border: 'none' }} scope="col">
                      {d.title}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {users?.map((d: any) => (
                  <>
                    <tr key={d.id} className="text-center">
                      <td className="u-numberrowwwww">{d.first_name}</td>
                      <td className="u-numberrowwwww">{d.last_name}</td>
                      <td className="u-numberrowwwww">{d.email}</td>
                      <td className="u-numberrowwwww">{d.phoneNumber}</td>
                      <td className="u-numberrowwwww">{d.role}</td>
                      <td className="u-numberrowwwww">
                        <div className="d-flex justify-content-center">
                          <span className="ml-2">
                            {!d.disabled ? <img src={images.Tick} alt="Yes" /> : <img src={images.Cross} alt="No" />}
                          </span>
                        </div>
                      </td>

                      <td className="u-numberrowwwww">
                        <div className="d-flex justify-content-center">
                          <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setUser(d);
                              setShowAddEditModal(true);
                            }}
                            className="u-menubutton001 pt-2 pb-2 mr-2"
                          >
                            Edit
                          </span>
                        </div>
                      </td>
                    </tr>
                    <hr style={{ display: 'table-row' }} />
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default withMainLayout(UserScreen);
