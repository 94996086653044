import React from 'react';
import { Area, CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

const Chart = (props: any) => {
  const { data, unit } = props;
  let tooltip: any;
  const CustomTooltip = ({ active, payload }: any) => {
    if (!active || !tooltip) return null;
    for (const bar of payload)
      if (bar.dataKey === tooltip)
        return (
          <div
            style={{
              background: '#FFFFFF',
              boxShadow: '0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06)',
              borderRadius: '15px',
            }}
            className="p-3"
          >
            <span style={{ color: '#979797' }}>{bar.payload.day}</span>
            <br />
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: '600',
              }}
            >
              {bar.value} {props.heading?.split(' ')[1] === 'Sales' ? 'AED' : ''}
            </span>
            <br />
            <span style={{ color: '#979797' }}>{props.heading?.split(' ')[1] === 'Sales' ? 'Sales' : 'Orders'}</span>
          </div>
        );

    return null;
  };
  return (
    <div
      style={{
        width: '100%',
        minHeight: '39vh',
      }}
      className={data.length > 0 && !props.loading ? '' : 'd-flex justify-content-center align-items-center'}
    >
      <React.Fragment>
        <h2 className="m-2 u-saleflash001">{data.length > 0 && props.heading}</h2>
        <ResponsiveContainer width="100%" height={400}>
          <ComposedChart height={300} data={data} margin={{ top: 25, right: 30, left: 20, bottom: 5 }}>
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="50%" stopColor="#8E5EFA" stopOpacity={0.2} />
                <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.1} />
              </linearGradient>
            </defs>

            <CartesianGrid strokeDasharray="6 6" vertical={false} stroke="#DDD" />
            <XAxis
              dataKey="day"
              axisLine={{ stroke: '#EAF0F4' }}
              style={{
                fontSize: 'small',
                opacity: '1',
                color: 'purple',
              }}
            />
            <YAxis
              tickCount={6}
              unit={unit}
              axisLine={{ stroke: '#EAF0F4' }}
              style={{ fontSize: 'small', opacity: '1' }}
            />
            <Tooltip content={<CustomTooltip />} />

            <Line type="monotone" stroke="#8E5EFA" strokeWidth={2} />
            <Area
              type="monotone"
              dataKey="sales"
              stroke="#8E5EFA"
              strokeWidth={2}
              fillOpacity={1}
              fill="url(#colorUv)"
              onMouseOver={() => (tooltip = 'sales')}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </React.Fragment>
    </div>
  );
};

export default Chart;
