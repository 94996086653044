import { images } from '../../../global/exports';
import BarChart from './barChart';

const Chart2 = (props: any) => {
  const { data, x, y1, y2, unit, keymetrics, keyMetrics } = props;
  return (
    <>
      <div className="col-lg-12 col-xl-7 col-md-12 pr-xl-1 pr-lg-1 pr-md-3 pl-0">
        <div className="mt-3 pr-xl-1 pr-lg-0">
          <div className="pl-2 pr-2 justify-content-between u-orderlefttabletotalcustomer">
            <BarChart
              loading={props.loading}
              heading={props.heading}
              h2="Discount cost"
              data={data}
              x={x}
              y1={y1}
              y2={y2}
              unit={unit}
            />
          </div>
        </div>
      </div>
      <div
        className="col-lg-12 col-xl-4 col-xl-5 mt-3 mx-lg-2 mx-xl-0 pl-lg-0 pr-3 bg-white"
        style={{ borderRadius: '10px' }}
      >
        <div className="p-2">
          <h2 style={{ fontWeight: '600' }} className=" u-menuflashsale00111 mb-3 ml-4 mt-5">
            Key Metrics
          </h2>
          <div className="row ml-1">
            <div className="col-lg-6 col-md-6">
              <div className="d-flex">
                <img height={6} className="mt-2" src={images.green} />
                <p className="u-flashsaelprice001 ml-2">{keymetrics?.name1}</p>
              </div>
              <h2 style={{ fontWeight: '600' }} className="u-menuflashsale00111 mb-3 ml-3">
                {keyMetrics?.sales ? (isNaN(keyMetrics?.sales) ? 0 : keyMetrics?.sales?.toFixed(0)) : 0}
                AED
              </h2>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="d-flex">
                <img height={6} className="mt-2" src={images.red} />
                <p className="u-flashsaelprice001 ml-2">{keymetrics.name2}</p>
              </div>
              <h2 style={{ fontWeight: '600' }} className="u-menuflashsale00111 mb-3 ml-3">
                {keyMetrics?.orders ? (isNaN(keyMetrics.orders) ? 0 : keyMetrics.orders?.toFixed(0) ?? 0) : 0}
              </h2>
            </div>
          </div>
          <div className="row ml-1">
            <div className="col-lg-6 col-md-6">
              <div className="d-flex">
                <img height={6} className="mt-2" src={images.blue} />
                <p className="u-flashsaelprice001 ml-2">{keymetrics.name3}</p>
              </div>
              <h2 style={{ fontWeight: '600' }} className="u-menuflashsale00111 mb-3 ml-3">
                {keyMetrics?.basket ? (isNaN(keyMetrics.basket) ? 0 : keyMetrics.basket?.toFixed(0) ?? 0) : 0}{' '}
                {keymetrics.name3.includes('basket') && 'AED'}
              </h2>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="d-flex">
                <img height={6} className="mt-2" src={images.dark} />
                <p className="u-flashsaelprice001 ml-2">{keymetrics.name4}</p>
              </div>
              <h2 style={{ fontWeight: '600' }} className="u-menuflashsale00111 mb-3 ml-3">
                41 AED
              </h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chart2;
