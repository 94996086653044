const ItemsRatings = (props: any) => {
  const { ratings, setData, setDataType } = props;
  return (
    <div className="row u-orderlefttablesatisfaction mt-3">
      <div className="col-lg-12">
        <h2 className="u-orderheading ">Items Rating</h2>
        <div style={{ maxHeight: '70vh', overflowX: 'hidden' }} className="table-responsive">
          <table className="table">
            <thead className="u-tablemenuheding">
              <tr style={{ textAlign: 'center' }}>
                <th className="u-ordertablemainhead text-left" style={{ border: 'none' }} scope="col">
                  Item
                </th>
                <th className="u-ordertablemainhead text-left" style={{ border: 'none' }} scope="col">
                  Rating
                </th>
                <th className="u-ordertablemainhead text-left" style={{ border: 'none' }} scope="col">
                  Components
                </th>
                <th className="u-ordertablemainhead text-left" style={{ border: 'none' }} scope="col">
                  Issues
                </th>
              </tr>
            </thead>
            <tbody>
              <>
                {ratings?.average_rating?.map((r: any) => (
                  <tr key={r?.id} className="u-bordercolorclass" style={{ textAlign: 'center' }}>
                    <td className="u-numberrowwwww pt-4">{r?.variant_name}</td>
                    <td className="u-numberrowwwww">
                      <div className="middle">
                        <div className="progress" style={{ width: '100%' }}>
                          <div
                            className="progress-bar"
                            style={{
                              width: `${(r?.rating / 5) * 100}%`,
                              backgroundColor: '#834bff',
                            }}
                            role="progressbar"
                            aria-valuenow={r?.rating}
                            aria-valuemin={0}
                            aria-valuemax={5}
                          ></div>
                        </div>
                      </div>
                      <p className="u-satifaction-rating mt-2">{r?.rating}</p>
                    </td>
                    <td className="pt-4">
                      {r?.reviews.map((v: any) => (
                        <>
                          <p className="u-numberrowwwww-rating pt-1 pb-1 m-1">{v.comment}</p>
                        </>
                      ))}
                    </td>
                    <td
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setData(ratings?.reviews);
                        setDataType('Item Rating Details');
                      }}
                      className="u-numberrowwwww-ratin001 pt-4"
                    >
                      Read all
                      <i style={{ cursor: 'pointer' }} className="fa-solid fa-chevron-right ml-2"></i>
                    </td>
                  </tr>
                ))}
              </>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ItemsRatings;
