import { images } from '../../../../global/exports';
import Chart3 from '../../chart/chart3';
import DashboardComponent from '../../dashboardComponent/dashboardComponent';

const RestroMarketing = (props: any) => {
  const { group300, blue, green, red, dark } = images;
  const data = [
    { day: '0', amount: 700, amount2: 6000 },
    { day: '3', amount: 250, amount2: 1500 },
    { day: '6', amount: 1000, amount2: 1500 },
    { day: '9', amount: 7000, amount2: 1500 },
    { day: '12', amount: 5000, amount2: 1500 },
    { day: '15', amount: 9000, amount2: 1500 },
    { day: '18', amount: 3000, amount2: 1500 },
    { day: '21', amount: 1000, amount2: 1500 },
  ];
  return (
    <>
      {' '}
      <div className="col-xl-9 col-lg-12 col-md-12  mt-3 mb-4 pr-0" style={{ height: '100%' }}>
        <h2
          style={{
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '22px',
            lineHeight: '26px',
            color: '#0C0B48',
          }}
        >
          Discount Campaigns
        </h2>
        <div className="row">
          <div className="col-lg-6 pr-3 pr-lg-1">
            <div className="p-3 justify-content-between u-orderlefttable">
              <Chart3 loading={props.loading} h1="Customers reached" h2="Discount cost" data={data} />
            </div>
          </div>
          <div className="col-lg-6 pr-3 pr-lg-0 pl-2 pl-lg-1 mt-lg-0 mt-3">
            <div className="p-3 justify-content-between u-orderlefttable">
              <Chart3 loading={props.loading} h1="Sales/ Customer" h2="Fqcy/ customer" data={data} />
            </div>
          </div>
          <div className="col-lg-12 col-xl-12 mt-3 pr-3 pr-lg-0">
            <div className="u-orderlefttabletotalcustomer">
              <h2 className="u-menukeymetrics mb-3">Key Metrics</h2>

              <div className="row ml-3 d-flex">
                <div className="col-lg-3 ">
                  <div className="d-flex">
                    <img height={6} className="mt-2" src={images.green} />
                    <p className="u-flashsaelprice001 ml-2">Messages Sent</p>
                    <h2 className="u-menuflashsale00111 mb-3 ml-3">100AED</h2>
                  </div>
                </div>

                <div className="col-lg-3 ">
                  <div className="d-flex">
                    <img height={6} className="mt-2" src={images.dark} />
                    <p className="u-flashsaelprice001 ml-2">Conversion</p>
                    <h2 className="u-menuflashsale00111 mb-3 ml-3">01%</h2>
                  </div>
                </div>
                <div className="col-lg-3 ">
                  <div className="d-flex">
                    <img height={6} className="mt-2" src={images.blue} />
                    <p className="u-flashsaelprice001 ml-2">Increase in sales vs previous period</p>
                    <h2 className="u-menuflashsale00111 mb-3 ml-3">01%</h2>
                  </div>
                </div>
                <div className="col-lg-3 ">
                  <div className="d-flex">
                    <img height={6} className="mt-2" src={images.dark} />
                    <p className="u-flashsaelprice001 ml-2"> Increase in fqcy vs previous period</p>
                    <h2 className="u-menuflashsale00111 mb-3 ml-3">01%</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <h2
          style={{
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '22px',
            lineHeight: '26px',
            color: '#0C0B48',
          }}
        >
          Sign-in discount
        </h2>
        <div className="row">
          <div className="col-lg-6 pr-3 pr-lg-1">
            <div className="p-3 justify-content-between u-orderlefttable">
              <Chart3 loading={props.loading} h1="Customers reached" h2="Discount cost" data={data} />
            </div>
          </div>
          <div className="col-lg-6 pr-3 pr-lg-0 pl-2 pl-lg-1 mt-lg-0 mt-3">
            <div className="p-3 justify-content-between u-orderlefttable">
              <Chart3 loading={props.loading} h1="Sales/ Customer" h2="Fqcy/ customer" data={data} />
            </div>
          </div>
          <div className="col-lg-12 col-xl-12 mt-3 pr-0">
            <div className="u-orderlefttabletotalcustomer">
              <h2 className="u-menukeymetrics mb-3">Key Metrics</h2>

              <div className="row ml-3 d-flex">
                <div className="col-lg-3 ">
                  <div className="d-flex">
                    <img height={6} className="mt-2" src={images.green} />
                    <p className="u-flashsaelprice001 ml-2 mb-0">Discount cost/ new customer</p>
                    <h2 className="u-menuflashsale00111 mt-1">100AED</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DashboardComponent fromHome={true} />
    </>
  );
};

export default RestroMarketing;
