const RestaurantSelect = ({ filter, restaurants }: any) => (
  <select
    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
      if (e.target.value === '') {
        filter(restaurants);
        return;
      }
      filter(restaurants.filter((curr: any) => curr.id === e.target.value));
    }}
    className="form-select u-menuselect002"
    aria-label="Default select example"
  >
    <option value="" className="u-menuselect001">
      Select All
    </option>
    {restaurants?.map((o: any) => (
      <option key={o.id} value={o.id}>
        {o.restaurant_name || o.name}
      </option>
    ))}
  </select>
);

export default RestaurantSelect;
