import React from 'react';
import { Bar, CartesianGrid, ComposedChart, Line, ResponsiveContainer, XAxis, YAxis } from 'recharts';

const Chart3 = (props: any) => (
  <React.Fragment>
    <div className="d-flex align-items-center justify-content-between pb-3">
      <span className="m-2 u-saleflash001">{props.h1}</span>
      <span className="m-2 float-right u-saleflash001">{props.h2}</span>
    </div>
    <ResponsiveContainer width="100%" height={350}>
      <ComposedChart width={1300} height={350} data={props.data}>
        <XAxis
          dataKey="day"
          axisLine={{ stroke: '#EAF0F4' }}
          style={{
            fontSize: 'small',
            opacity: '1',
            color: 'purple',
          }}
        />
        <YAxis
          yAxisId="right-axis"
          orientation="right"
          unit="  AED"
          tickCount={6}
          axisLine={{ stroke: '#EAF0F4' }}
          style={{ fontSize: 'small', opacity: '1' }}
        />
        <YAxis
          tickCount={6}
          unit="  AED"
          axisLine={{ stroke: '#EAF0F4' }}
          style={{ fontSize: 'small', opacity: '1' }}
        />
        <CartesianGrid strokeDasharray="6 6" vertical={false} stroke="#DDD" />

        {/* <Tooltip /> */}

        <Bar dataKey="amount2" barSize={40} fill="#D4C0FF" />
        <Line yAxisId="right-axis" type="monotone" dataKey="amount" stroke="#834BFF" strokeWidth={2} />
      </ComposedChart>
    </ResponsiveContainer>
  </React.Fragment>
);

export default Chart3;
