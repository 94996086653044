import { useState } from 'react';
import { Modal } from 'react-bootstrap';

const ForgetPassword = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [body, setBody] = useState({
    email: '',
  });
  const loadingBtnStyle = { padding: '13px', backgroundColor: '#cccccc' };
  const forgetPass = (e: any) => {
    e.preventDefault();
    setLoading(true);
  };

  return (
    <Modal show={props.show}>
      <div>
        <button onClick={() => props.setShow(false)} type="button" className="p-3 close">
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="p-4 u-orderlefttable">
          <h2 className="u-login0011 mb-3">Forget Password</h2>

          <form className="signup" onSubmit={forgetPass}>
            <div className="form-group">
              <label className="u-userlogin001">Enter your Email</label>
              <input
                onChange={(e) => setBody({ ...body, email: e.target.value })}
                style={{
                  backgroundColor: '#F9F9F9',
                  borderRadius: '10px',
                  border: 'none',
                  fontSize: '20px',
                  fontFamily: 'roboto',
                }}
                type="email"
                required
                className="form-control inputField ml-0"
              />
            </div>

            <button disabled={loading} style={loading ? loadingBtnStyle : {}} className="u-loginbutton001 mt-3">
              Reset Password
            </button>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default ForgetPassword;
