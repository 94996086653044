const RatingStars = ({ review }: any) => {
  const { rating } = review;
  return (
    <span className="my-3 ml-2 mr-2">
      <span style={{ color: '#834BFF' }}>
        <span>
          <i className={rating >= 1 ? 'fas fa-star' : rating >= 0.5 ? 'fas fa-star-half-alt' : 'far fa-star'}></i>
        </span>
        <span>
          <i
            className={rating - 1 >= 1 ? 'fas fa-star' : rating - 1 >= 0.5 ? 'fas fa-star-half-alt' : 'far fa-star'}
          ></i>
        </span>
        <span>
          <i
            className={rating - 2 >= 1 ? 'fas fa-star' : rating - 2 >= 0.5 ? 'fas fa-star-half-alt' : 'far fa-star'}
          ></i>
        </span>
        <span>
          <i
            className={rating - 3 >= 1 ? 'fas fa-star' : rating - 3 >= 0.5 ? 'fas fa-star-half-alt' : 'far fa-star'}
          ></i>
        </span>
        <span>
          <i
            className={rating - 4 >= 1 ? 'fas fa-star' : rating - 4 >= 0.5 ? 'fas fa-star-half-alt' : 'far fa-star'}
          ></i>
        </span>
      </span>
    </span>
  );
};

export default RatingStars;
