import axios from 'axios';
import { auth } from 'config/firebase';
import { serverUrl } from '../routes/ServerRoutes';
import { getCurrentUser } from './getCurrentUser';

const Api = axios.create({
  baseURL: serverUrl,
});
Api.interceptors.request.use(async (config: any) => {
  const controller = new AbortController();
  const token = await getCurrentUser()?.getIdToken();
  const { tenantId } = auth;
  if (token) {
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    config.headers['X-TENANT-ID'] = tenantId || undefined;
    return config;
  }
  controller.abort();
});

Api.interceptors.response.use(
  (res: any) => res,
  (error) => Promise.reject(error?.response),
);

// a generic code to handle all api calls with ease
export const apiRequest = async (url: string, method: string, body: any) => {
  try {
    switch (method) {
      case 'get':
        return await Api.get(url);
      case 'delete':
        return await Api.delete(url);
      case 'post':
        return await Api.post(url, body);
      case 'patch':
        return await Api.patch(url, body);
      case 'put':
        return await Api.put(url, body);
      default:
        return await Api.get(url);
    }
  } catch (err: any) {
    throw new Error(err?.data?.message || err?.data?.error);
  }
};

export default apiRequest;
