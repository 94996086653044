// this files will import from different locations and export it. so we can import anywhere from this file
import barCode from '../../../assets/Images/barcode.png';
import bell from '../../../assets/Images/bell.svg';
import bell2 from '../../../assets/Images/bell2.svg';
import bestrouser from '../../../assets/Images/bestrouser.png';
import blue from '../../../assets/Images/blue.png';
import calendar from '../../../assets/Images/calendar.png';
import checkone from '../../../assets/Images/checkone.png';
import checktwo from '../../../assets/Images/checktwo.png';
import Cross from '../../../assets/Images/crosss.png';
import crown from '../../../assets/Images/crown.png';
import dark from '../../../assets/Images/dark.png';
import deleteIcon from '../../../assets/Images/delete.svg';
import downArrow from '../../../assets/Images/downarrow.png';
import editorder from '../../../assets/Images/editorder.png';
import gdelete from '../../../assets/Images/gdelete.png';
import graphSale from '../../../assets/Images/graphsale.png';
import green from '../../../assets/Images/green.png';
import group499 from '../../../assets/Images/Group-499.png';
import group501 from '../../../assets/Images/Group-501.png';
import group300 from '../../../assets/Images/group300.png';
import group33 from '../../../assets/Images/group33.png';
import group34 from '../../../assets/Images/group34.png';
import gupload from '../../../assets/Images/gupload.png';
import history from '../../../assets/Images/history.png';
import home from '../../../assets/Images/home.png';
import InProgressLogo from '../../../assets/Images/inpro.png';
import line from '../../../assets/Images/line.png';
import lineuser from '../../../assets/Images/lineuser.png';
import lock from '../../../assets/Images/lock.png';
import manage from '../../../assets/Images/manage.png';
import menu from '../../../assets/Images/menu.png';
import Menudot from '../../../assets/Images/menudot.png';
import Menudrop from '../../../assets/Images/menudrop.png';
import menuside from '../../../assets/Images/menuside.png';
import note from '../../../assets/Images/note.png';
import orderarrow from '../../../assets/Images/oderarrow.png';
import PaidIcon from '../../../assets/Images/paidicon.png';
import printer from '../../../assets/Images/printer.png';
import PrintIcon from '../../../assets/Images/printicon.png';
import R from '../../../assets/Images/R.png';
import red from '../../../assets/Images/red.png';
import restrologo from '../../../assets/Images/restrologo.png';
import restrologot from '../../../assets/Images/restrologot.png';
import searchNormal from '../../../assets/Images/search-normal.png';
import setting from '../../../assets/Images/setting.png';
import settings from '../../../assets/Images/settings.png';
import Share from '../../../assets/Images/share.png';
import starRating from '../../../assets/Images/starrating.png';
import statusUp from '../../../assets/Images/status-up.png';
import Tick from '../../../assets/Images/tiock.png';
import trash from '../../../assets/Images/trash.png';
import upload from '../../../assets/Images/upload.jpg';
import upsellrit from '../../../assets/Images/upsellrit.png';
import WaitIcon from '../../../assets/Images/waiticon.png';

import { apiRequest } from '../../../utils/axios';
import { isEmptyObj } from '../../../utils/emptyObject';
import { localDate } from '../../../utils/firebaseDateConvert';
import { timeConvertHM } from '../../../utils/timeConverter';

// services (actions)

export const images = {
  deleteIcon,
  starRating,
  dark,
  upsellrit,
  statusUp,
  group499,
  group501,
  blue,
  bell2,
  red,
  green,
  calendar,
  group300,
  downArrow,
  graphSale,
  upload,
  restrologot,
  restrologo,
  barCode,
  trash,
  setting,
  settings,
  checktwo,
  lineuser,
  checkone,
  editorder,
  Menudot,
  group33,
  group34,
  Share,
  Menudrop,
  Cross,
  Tick,
  lock,
  bell,
  bestrouser,
  menu,
  searchNormal,
  home,
  note,
  history,
  menuside,
  crown,
  manage,
  R,
  line,
  PrintIcon,
  printer,
  InProgressLogo,
  PaidIcon,
  WaitIcon,
  orderarrow,
  gupload,
  gdelete,
};

export const utils = {
  timeConvertHM,
  apiRequest,
  isEmptyObj,
  localDate,
};
